import {ActionIcon, Anchor, Badge, Button, Group} from "@mantine/core";
import {Link} from "wouter";
import {useYoApi} from "@hooks/useYoApi.tsx";
import {WebAppsApiClient} from "./api.ts";
import {Header} from "@components/Header";
import {useNavigate} from "@hooks/useNavigate.tsx";
import {useMemo, useState} from "react";
import {MantineReactTable, MRT_ColumnDef, useMantineReactTable} from "mantine-react-table";
import {useMRTYoApiDataAdapter} from "@hooks/useMRTYoApiDataAdapter.tsx";
import {useMRTState} from "@hooks/useMRTState.tsx";
import {useDefaultsTableState} from "@hooks/useDefaultsTableState.tsx";
import {yoFormModal} from "@modals/yoFormModal.tsx";
import {InputField} from "@components/YoContextEditor/components/inputField.tsx";
import {displayErrorNotification} from "@serviceComponents/displayErrorNotification.tsx";
import {useLocale, useLocaleContext} from "../../locale";
import {RenderPwaTableStat} from "./renderPwaTableStat.tsx";
import {useUser} from "@engine/UserContext.tsx";
import {viewOwnerColl} from "../../utils/viewOwnerColl.tsx";
import useTeams from "../Teams/Context/useTeams.ts";
import {IconCopyPlus, IconEdit} from "@tabler/icons-react";
import {allLanguageList} from "@shared/_data/allLanguageList.ts";
import {CopyDomainMenu} from "./copyDomainMenu.tsx";

interface IItem {
	_id: string;
}

const api = new WebAppsApiClient();

function RenderPwaLangList(props: { items: Array<string> }) {
	const [showAll, setShowAll] = useState(false);
	const {selectedLocale} = useLocaleContext();
	const elements = useMemo(() => {
		const values = allLanguageList.filter(el => props.items.includes(el.code));
		return values.map(el => {
			const name = selectedLocale === 'ru' ? el.name_ru : selectedLocale === 'uk' ? el.name_ukr : el.name_en;
			return (
				<Badge color={'dark'} title={name}>
					{el.emoji} {name}
				</Badge>
			);
		})
	}, [...props.items]);

	const firstElements = elements.slice(0,3);
	const allElementsCount = elements.length - firstElements.length;

	if(showAll) {
		return <div onClick={() => setShowAll(false)}>{elements}</div>
	}

	return <div>{firstElements}{allElementsCount ? <Badge style={{cursor: 'pointer'}} onClick={() => setShowAll(true)}>+{allElementsCount}</Badge>: null}</div>
}

export function ListWebApps({showArchived}: {showArchived?: boolean}) {
	const t = useLocale();
	const navigate = useNavigate();
	const defaults = useDefaultsTableState();
	const {user} = useUser();
	const {selectedLocale} = useLocaleContext();
	const { activeTeam, myTeams } = useTeams();
	const role = myTeams.find(e => e._id === activeTeam)?.role || 'admin';

	const createTableState = useMRTState<IItem>({
		visibilityLocalStorageKey: 'ui.WebApps.columnVisibility',
		defaults
	});

	const columns = useMemo(() => {
		const cols =  [{
			accessorKey: 'name',
			header: t('webApps.list.table.name'),
		}, {
			accessorKey: 'domain.domain',
			header: t('webApps.list.table.domain'),
			Cell: (props) => {
				const domain = props.cell.getValue<string>();
				if (!domain) return <Badge color={'yellow'}>{t('webApps.list.table.domain.NONE')}</Badge>
				return <Group justify="space-between" wrap="nowrap" w="100%">
					<Anchor target={'_blank'} rel={'noreferrer noopener'} href={`https://${domain}?fromDashboard`}>{domain}</Anchor>
					<CopyDomainMenu domain={domain}/>
				</Group>

			}
		}, {
			accessorKey: 'state',
			header: t('webApps.list.table.status'),
			Cell: (props) => {
				if (props.cell.getValue() === 'PUBLISHED') return <Badge color={'green'}>{t('webApps.list.table.status.PUBLISHED')}</Badge>;
				if (props.cell.getValue() === 'DRAFT') return <Badge color={'blue'}>{t('webApps.list.table.status.DRAFT')}</Badge>;
				return <Badge color={'red'}>{t('webApps.list.table.status.DEFAULT')}</Badge>;
			}
		}, {
			accessorKey: '__locales',
			header: t('webApps.list.table.locales'),
			enableSorting: false,
			filterVariant: 'multi-select',
			yoFilerType: 'in',
			mantineFilterMultiSelectProps: {
				data: allLanguageList.map(el => {
					const name = selectedLocale === 'ru' ? el.name_ru : selectedLocale === 'uk' ? el.name_ukr : el.name_en;
					return {value: el.code, label: `${el.emoji} ${name}`};
				})
			},
			Cell: props => <RenderPwaLangList items={props.cell.getValue<string[]>()}/>
		}, {
			accessorKey: '__stats',
			header: t('webApps.list.table.statistics'),
			enableSorting: false,
			enableColumnActions: false,
			Cell: props => <RenderPwaTableStat id={props.row.original._id} period={'today'}/>
		}, {
			accessorKey: 'actions',
			header: t('webApps.list.table.actions'),
			enableSorting: false,
			enableColumnActions: false,
			Cell: (props) => (
				<Group gap="xs">
					<ActionIcon<'a'>
						variant="subtle" c="white"
						title={t('webApps.list.table.actions.edit')}
						component={Link as any}
						href={`/v/${props.row.original._id}`}
					>
						<IconEdit />
					</ActionIcon>
					<ActionIcon
						variant="subtle" c="white"
						title={t('webApps.list.table.actions.clone')}
						onClick={() => tryClone(props.row.original)}
					>
						<IconCopyPlus />
					</ActionIcon>
				</Group>
			)
		}] as MRT_ColumnDef<IItem>[];
		if(user.isSuperAdmin || role === 'admin' || role === 'techSpecialist' || role === 'pushSpecialist' || role === 'financier') cols.unshift(viewOwnerColl as MRT_ColumnDef<IItem>);
		return cols;
	}, [role]);

	const data = useYoApi({
		apiClient: api,
		defaults,
		defaultSort: '-_id',
		requestParams: {archived: showArchived}
	});
	const tableState = createTableState({
		data,
		columns: columns.filter(Boolean)
	});
	useMRTYoApiDataAdapter({
		apiClient: data,
		tableParams: tableState.adapterProps,
		defaults,
	});
	const table = useMantineReactTable<IItem>(tableState);

	async function tryCreate() {
		const data = await yoFormModal<any>({
			title: t('webApps.list.create.title'),
			required: ['name'],
			defaultValue: {comments: []},
			size: 'lg',
			body: (
				<>
					<InputField field={'name'} label={t('webApps.list.create.name')}/>
				</>
			)
		})
		return api.createItem({ ...data, team: activeTeam }).then(res => navigate(`/v/${res.item._id}`)).catch(displayErrorNotification);
	}

	async function tryClone(item: any) {
		const data = await yoFormModal<any>({
			title: `${t('webApps.list.clone.title')} "${item.name}"`,
			size: 'lg',
			body: (
				<>
					<InputField
						field={'name'}
						label={t('webApps.list.clone.name')}
						placeholder={`${item.name} (copy)`}
					/>
				</>
			)
		})
		return api.cloneItem(item._id, { ...data, team: activeTeam }).then(res => navigate(`/v/${res.item._id}`)).catch(displayErrorNotification);
	}

	return <>
		<Header
			title={showArchived? t('webApps.archive.title') : t('webApps.list.title')}
			items={(
				<>
					{showArchived ? (
						<>
							<Button component={Link} href={'/'} variant="primary" size={'compact-sm'}>{t('webApps.archive.goBack')}</Button>
						</>
					): (
						<>
							<Button component={Link} href={'/archive'} variant="primary" size={'compact-sm'}>{t('webApps.list.archive')}</Button>
							<Button variant="primary" size={'compact-sm'} onClick={tryCreate}>{t('webApps.list.create')}</Button>
						</>
					)}

				</>
			)}
		/>
		<MantineReactTable
			table={table}
		/>
	</>;
}
