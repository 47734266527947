import {Button, Flex} from "@mantine/core";
import {Link} from "wouter";
import {useYoApi} from "@hooks/useYoApi.tsx";
import {FbPixelsApiClient} from "./api.ts";
import {Header} from "@components/Header";
import {useNavigate} from "@hooks/useNavigate.tsx";
import {useMemo} from "react";
import {
	MantineReactTable,
	useMantineReactTable,
	MRT_ColumnDef
} from "mantine-react-table";
import {useMRTYoApiDataAdapter} from "@hooks/useMRTYoApiDataAdapter.tsx";
import {useMRTState} from "@hooks/useMRTState.tsx";
import {useDefaultsTableState} from "@hooks/useDefaultsTableState.tsx";
import {yoFormModal} from "@modals/yoFormModal.tsx";
import {InputField} from "@components/YoContextEditor/components/inputField.tsx";
import {IconEdit, IconPaperBag} from "@tabler/icons-react";
import {Translation, useLocale} from "../../locale";
import {useUser} from "@engine/UserContext.tsx";
import {viewOwnerColl} from "../../utils/viewOwnerColl.tsx";
import useTeams from "../Teams/Context/useTeams.ts";

const api = new FbPixelsApiClient()

interface IItem {
	_id: string;
}

export function ListFbPixels() {
	const t = useLocale();
	const {user} = useUser();
	const navigate = useNavigate();
	const defaults = useDefaultsTableState();
	const { activeTeam } = useTeams();

	const createTableState = useMRTState<IItem>({
		visibilityLocalStorageKey: 'ui.FbPixels.columnVisibility',
		defaults
	});

	const columns = useMemo(() => {
		const cols =  [{
			accessorKey: 'name',
			header: t('fbPixels.list.tableHeader.name'),
		}, {
			accessorKey: 'pixel',
			header: t('fbPixels.list.tableHeader.pixel'),
		}, {
			accessorKey: 'actions',
			header: t('fbPixels.list.tableHeader.actions'),
			enableSorting: false,
			enableColumnActions: false,
			Cell: (props) => (
				<Flex align={'center'} gap={'sm'}>
					<Link href={`/v/${props.row.original._id}`}>
						<a>
							<Button size={'compact-sm'}>
								<IconEdit/>
							</Button>
						</a>
					</Link>
					<Link href={`/logs/${props.row.original._id}`}>
						<a>
							<Button size={'compact-sm'}>
								<IconPaperBag/>
							</Button>
						</a>
					</Link>

				</Flex>
			)
		}] as MRT_ColumnDef<IItem>[];

		if(user.isSuperAdmin) cols.unshift(viewOwnerColl as MRT_ColumnDef<IItem>);

		return cols;
	}, []);

	const data = useYoApi({ apiClient: api, defaults });
	const tableState = createTableState({
		data,
		columns
	});
	useMRTYoApiDataAdapter({
		apiClient: data,
		tableParams: tableState.adapterProps,
		defaults,
	});
	const table = useMantineReactTable<IItem>(tableState);

	async function tryCreate() {
		const data = await yoFormModal<any>({
			title: t('fbPixels.modal.title'),
			required: ['pixel', 'token'],
			defaultValue: {},
			size: 'lg',
			body: (
				<>
					<InputField field={'name'} label={t('fbPixels.modal.label.name')} />
					<InputField field={'pixel'} label={t('fbPixels.modal.label.pixel')} />
					<InputField field={'token'} label={t('fbPixels.modal.label.token')} />
				</>
			)
		})
		return api.createItem({ ...data, team: activeTeam }).then(res => navigate(`/v/${res.item._id}`))
	}

	return <>
		<Header
			title={t('fbPixels.header.title')}
			items={(
				<>
					<Button size={'compact-sm'} onClick={tryCreate}>
						<Translation id={'fbPixels.header.button.newPixel'}/>
					</Button>
				</>
			)}
		/>
		<MantineReactTable table={table} />
	</>;
}

