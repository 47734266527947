import {useEffect, useState} from "react";
import {Link, useParams} from "wouter";
import {usePromise} from "@hooks/usePromise.ts";
import {YoContextEditor} from "@components/YoContextEditor";
import {InputField} from "@components/YoContextEditor/components/inputField.tsx";
import {Alert, Button, Card} from "@mantine/core";

import {DomainsApiClient} from "./api.ts";
import {Header} from "@components/Header";
import {ConfirmDeleteModal} from "@modals/confirmModal.tsx";
import {useNavigate} from "@hooks/useNavigate.tsx";
import {RenderFuncField} from "@components/YoContextEditor/components/renderFuncField.tsx";
import {TextareaField} from "@components/YoContextEditor/components/textareaField.tsx";
import {useLocale} from "../../locale";
import TeamSelectField from "@components/YoContextEditor/teamSelect.tsx";
import {notifications} from "@mantine/notifications";
import {displayErrorNotification} from "@serviceComponents/displayErrorNotification.tsx";

const api = new DomainsApiClient();
export function ViewDomains() {
	const t = useLocale();
	const navigate = useNavigate();
	const params = useParams<{id: string}>();
	const promise = usePromise(() => api.fetchItem(params.id), [params.id]);
	const [data, setData] = useState({});
	const [changes, setChanges] = useState({});
	useEffect(() => setData(promise.data?.item ?? {}), [promise.isLoading]);


	async function tryRemove() {
		if(!await ConfirmDeleteModal()) return false;
		return api.deleteItem(params.id).then(() => navigate('/'))
	}

	async function trySave () {
		api.saveItem(params.id, changes).then(() => {
			notifications.show({message: 'Сохранение успешно'});
			setChanges({});
		}).catch(displayErrorNotification)
	}

	return (
		<YoContextEditor item={data} setItem={setData} changes={changes} setChanges={setChanges}>
			<Header
				title={t('domainsPage.view.title')}
				items={(
					<>
						<Button onClick={trySave} variant="primary" size={'compact-sm'}>
							Save
						</Button>
						<Button variant="outline" c="red" size={'compact-sm'} onClick={tryRemove}>{t('domainsPage.view.action.delete')}</Button>
					</>
				)}
				backButtonUrl=""
			/>
			<Card variant="section">
				<TeamSelectField />
				<InputField field={'domain'} label={t('domainsPage.view.form.domain')} readOnly/>
				<TextareaField field={'comment'} label={t('domainsPage.view.form.comment')} autosize/>
				<br/>
				<RenderFuncField<{_id: string, state: string, subStatus?: string, errReason?: string}> field={'buyJob'}>
					{ctx => {
						if (!ctx.value) return null;

						const { state, subStatus, errReason } = ctx.value;

						return (
							<>
								{state === 'WAIT' && (
									<Alert color={'yellow'} title={'Выкуп домена'}>
										{t('domainsPage.view.status.WAIT')}
										<br/>
										<Link href={`~/jobs/v/${ctx.value._id}`}>
											<Button>{t('domainsPage.view.status.link.label')}</Button>
										</Link>
									</Alert>
								)}
								{state === 'IN_PROGRESS' && (
									<Alert color={'blue'}  title={'Выкуп домена'}>
										{t('domainsPage.view.status.IN_PROGRESS')}{subStatus}
										<br/>
										<Link href={`~/jobs/v/${ctx.value._id}`}>
											<Button>{t('domainsPage.view.status.link.label')}</Button>
										</Link>
									</Alert>
								)}
								{state === 'DONE' && (
									<Alert color={'green'} title={'Выкуп домена'}>
										{t('domainsPage.view.status.DONE')}
										<br/>
										<Link href={`~/jobs/v/${ctx.value._id}`}>
											<Button>{t('domainsPage.view.status.link.label')}</Button>
										</Link>
									</Alert>
								)}
								{state === 'ERROR' && (
									<Alert color={'red'} title={'Выкуп домена'}>
										{t('domainsPage.view.status.ERROR')}{errReason}
										<br/>
										<Link href={`~/jobs/v/${ctx.value._id}`}>
											<Button>{t('domainsPage.view.status.link.label')}</Button>
										</Link>
									</Alert>
								)}
							</>
						);
					}}
				</RenderFuncField>
			</Card>
		</YoContextEditor>
	);
}
