import {YoApiClient} from "@engine/yoApiClient.tsx";
import {singleton} from "@ood/singleton";

interface iValueWithDiff {
	value: number,
	diff: number
}

type AtLeastOne<T> = {
	[K in keyof T]: Pick<T, K> & Partial<Omit<T, K>>;
}[keyof T];

export type iStatsSelector = AtLeastOne<{leadId: string, appId: string, jobId: string, messageId: string}>;

interface TimeRange {
	from: Date,
	to: Date
}


export type statsTypes = 'sent' | 'opened' | 'delivered' | 'failed';

@singleton
export class PushLogsApiClient extends YoApiClient {
	path = '/api/v1/push/logs';

	async fetchStats(filter: TimeRange, selector: {leadId?: string, appId?: string, jobId?: string, messageId?: string}) {
		return this.ky.post('stats', {json: {...filter, ...selector}}).json<{status: boolean, sent: iValueWithDiff, failed: iValueWithDiff, delivered: iValueWithDiff, opened: iValueWithDiff}>();
	}

	async fetchStatsChart(chartType: "sent" | "opened" | "delivered" | "failed", timeRange: TimeRange, selector: iStatsSelector) {
		return this.ky.post(`stats/chart/${chartType}`, {json: {...timeRange, ...selector}}).json<{status: boolean, chart: any[], series: any[]}>();
	}
}
