import {YoContextEditor} from "@components/YoContextEditor";
import {InputField} from "@components/YoContextEditor/components/inputField.tsx";
import {Header} from "@components/Header";
import {Button, Fieldset} from "@mantine/core";
import {notifications} from "@mantine/notifications";
import {useSiteSettings} from "@hooks/UseSiteSettings.tsx";
import {Translation, useLocale} from "../../../../locale";
import {displayErrorNotification} from "@serviceComponents/displayErrorNotification.tsx";

/*MenuManager.RegisterItem({
	path: '/system/settings/godaddy',
	parent: 'system',
	key: 'godaddySettings',
	name: 'Настройки регистратора godaddy',
	nameKey: 'menuItems.system.godaddySettings',
	icon: <IconWorldWww/>
})*/

export function GoDaddySettingsPage() {
	const controller = useSiteSettings('domains.godaddy.');
	const t = useLocale();

	function saveSettings() {
		return controller.saveChanges()
			.then(() => notifications.show({message: t('settings.general.savedMessage')}))
			.catch(displayErrorNotification)
	}

	return (
		<YoContextEditor {...controller.controller}>
			<Header title={t('settings.godaddy.title')} items={(
				<>
					<Button onClick={saveSettings}>
						<Translation id={'settings.general.saveBth'}/>
					</Button>
				</>
			)}/>
			<InputField field={'apiKey'} label={t('settings.godaddy.apiKey')}/>
			<InputField field={'secret'} label={t('settings.godaddy.secret')}/>
			<InputField field={'baseUrl'} label={'baseUrl'}/>
			<InputField field={'currency'} label="currency"/>
			<Fieldset legend="testAccount" mt="xl">
				<InputField field={'testAccount.apiKey'} label={t('settings.godaddy.apiKey')}/>
				<InputField field={'testAccount.secret'} label={t('settings.godaddy.secret')}/>
			</Fieldset>
		</YoContextEditor>
	);
}
