import {YoApiClient} from "@engine/yoApiClient.tsx";
import {singleton} from "@ood/singleton";

@singleton
export class FbPixelsApiClient extends YoApiClient {
	path = '/api/v1/fbPixels';

	fetchStats(id: string, queryValue: any) {
		return this.ky.post(`${id}/stats`, {json: queryValue}).json<any>();
	}

	fetchStatsChart(id: string, queryValue: any) {
		return this.ky.post(`${id}/stats/chart`, {json: queryValue}).json<any>();
	}
}
