import {Alert, Button, Card, Flex, Text, TextInput, Title} from "@mantine/core";
import {CheckboxField} from "@components/YoContextEditor/components/checkboxField.tsx";
import {CollapseField} from "@components/YoContextEditor/components/CollapseField.tsx";
import {InputField} from "@components/YoContextEditor/components/inputField.tsx";
import {SelectField} from "@components/YoContextEditor/components/selectField.tsx";
import {CopyButtonWithTooltip} from "../../../utils/copyButtonWithTooltip.tsx";
import {TagsInputField} from "@components/YoContextEditor/components/tagsInputField.tsx";
import {RenderFuncField} from "@components/YoContextEditor/components/renderFuncField.tsx";
import {FbPixelsSelect} from "../../FbPixels/FBPixelsSelect.tsx";
import {GoogleTrackingEntrySelect} from "../../GoogleTrackingEntry/GoogleTrackingEntrySelect.tsx";
import {YoContextArrayMap} from "@components/YoContextEditor/YoContextArrayMap.tsx";
import {mongoObjectId} from "../../../utils/mongoObjectId.ts";
import {IconInfoCircle, IconTrash} from "@tabler/icons-react";
import {yoFormModal} from "@modals/yoFormModal.tsx";
import {getLocaleString, useScopedLocale} from "../../../locale";
import {useUser} from "@engine/UserContext.tsx";

function showClaplyFindInfo() {

	return yoFormModal({
		title: 'Claply',
		size: 'xl',
		body: (
			<>
				<p>
					{getLocaleString('webApps.view.AnalyticsTab.clarity.findInfo.description')}
				</p>
				<pre>
        {`<script type="text/javascript">
  (function(c,l,a,r,i,t,y){
      c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
      t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
      y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
  })(window, document, "clarity", "script", `}
					<span style={{color: 'red'}}>"xxxxxxxxxx"</span>
					{`);
</script>`}
      </pre>
			</>
		)
	})
}

export function AnalyticsTab() {
	const tt = useScopedLocale('webApps.view.AnalyticsTab.');
	const {settings} = useUser();

	const postbackUrl = `${settings.postbackBaseUrl ? settings.postbackBaseUrl : `${window.location.protocol}//${window.location.host}`}/api/pub/appEvent?userId={external_id}&eventName={status}`;
	return (
		<Card withBorder>
			<Title order={4} mb="xs">{tt('keitaro.title')}</Title>
			<Alert icon={<IconInfoCircle/>} variant="info2" mb="lg" title={tt('keitaro.description')}>
				<CheckboxField
					field={'keitaro.enabled'}
					label={tt('keitaro.enabled.label')}
					disabled
					description={tt('keitaro.enabled.description')}
				/>
				<CollapseField field={'keitaro.enabled'}>
					<InputField field={'keitaro.apiUrl'} label={tt('keitaro.apiUrl.label')}/>
					<InputField field={'keitaro.apiKey'} label={tt('keitaro.apiKey.label')}/>
				</CollapseField>
			</Alert>

			<Title order={4} mb="xs">{tt('gTag.title')}</Title>
			<Alert icon={<IconInfoCircle />} variant="info2" mb="lg" title={tt('gTag.description')}>
				<CheckboxField field={'gTag.enabled'} label={tt('gTag.enabled')}/>
				<CollapseField field={'gTag.enabled'}>
					<InputField field={'gTag.gTagId'} label={tt('gTag.gTagId')}/>
					<InputField field={'gTag.installEventName'} label={tt('gTag.installEventName')}/>
				</CollapseField>
			</Alert>

			<Title order={4} mb="xs">{tt('clarity.title')}</Title>
			<Alert icon={<IconInfoCircle />} variant="info2" mb="lg" title={tt('clarity.description')}>
				<CheckboxField field={'clarity.enabled'} label={tt('clarity.enabled')}/>
				<CollapseField field={'clarity.enabled'}>
					<Flex align={'flex-end'} gap={'md'}>
						<InputField
							field={'clarity.tagId'}
							label={tt('clarity.tagId')}
							style={{width: '90%'}}
						/>
						<Button size={'sm'} onClick={() => showClaplyFindInfo()}>
							{tt('clarity.findInfo')}
						</Button>

					</Flex>

				</CollapseField>
			</Alert>

			<Title order={4} mb="xs">{tt('googleTracking.title')}</Title>
			<Alert icon={<IconInfoCircle />} variant="info2" mb="lg" title={tt('googleTracking.description')}>
				<CheckboxField field={'googleTracking.enabled'} label={tt('googleTracking.enabled')}/>
				<CollapseField field={'googleTracking.enabled'}>
					<hr/>
					<RenderFuncField<string> field={'googleTracking.entry'}>
						{ctx => (
							<GoogleTrackingEntrySelect
								label={tt('googleTracking.entry.label')}
								value={ctx.value}
								onChange={ctx.onChange as any}
								searchable
							/>
						)}
					</RenderFuncField>
				</CollapseField>
			</Alert>

			<Title order={4} mb="xs">{tt('facebook.title')}</Title>
			<Alert icon={<IconInfoCircle />} variant="info2" mb="lg" title={tt('facebook.description')}>
				<CheckboxField field={'facebook.enabled'} label={tt('facebook.enabled')}/>
				<CollapseField field={'facebook.enabled'}>
					<h4>{tt('facebook.mainPixel')}</h4>
					<RenderFuncField<string> field={'facebook.authData'}>
						{ctx => (
							<FbPixelsSelect
								label={tt('facebook.mainPixel.label')}
								value={ctx.value}
								onChange={ctx.onChange as any}
								searchable
							/>
						)}
					</RenderFuncField>
					<br/>
					<CheckboxField
						field={'facebook.requireFBP'}
						label={tt('facebook.requireFBP.label')}
						description={tt('facebook.requireFBP.description')}
					/>
					<br/>
					<h4>{tt('facebook.additionalPixels.title')}</h4>
					<YoContextArrayMap field={'facebook.additionalPixels'}>
						<RenderFuncField<string> field={'pixel'}>
							{ctx => (
								<FbPixelsSelect
									label={tt('facebook.additionalPixels.label')}
									value={ctx.value}
									onChange={ctx.onChange as any}
									readOnly
									rightSectionPointerEvents={'all'}
									rightSection={(
										<IconTrash onClick={ctx.bypassProps.onRemove}/>
									)}
								/>
							)}
						</RenderFuncField>
						<br/>
						<hr/>
					</YoContextArrayMap>
					<hr/>
					<RenderFuncField field={'facebook.additionalPixels'}>
						{ctx => (
							<Button
								disabled={ctx.bypassProps.readOnly}
								size={'compact-sm'}
								fullWidth
								onClick={() =>
									yoFormModal({
										title: tt('facebook.additionalPixels.add.modalTitle'),
										required: ['pixel'],
										body: (
											<>
												<RenderFuncField<string> field={'pixel'}>
													{ctx => (
														<FbPixelsSelect
															label={tt('facebook.additionalPixels.label')}
															value={ctx.value}
															onChange={ctx.onChange as any}
															searchable
														/>
													)}
												</RenderFuncField>
											</>
										)
									}).then(obj => ctx.push({_id: mongoObjectId(), ...obj}))}
							>
								{tt('facebook.additionalPixels.add.buttonLabel')}
							</Button>
						)}
					</RenderFuncField>
				</CollapseField>
			</Alert>

			<Title order={4} mb="xs">{tt('onInstallWebHook.title')}</Title>
			<Alert icon={<IconInfoCircle />} variant="info2" mb="lg" title={tt('onInstallWebHook.description')}>
				<CheckboxField field={'onInstallWebHook.enabled'} label={tt('onInstallWebHook.enabled')}/>
				<CollapseField field={'onInstallWebHook.enabled'}>
					<InputField field={'onInstallWebHook.url'} label={tt('onInstallWebHook.url')}/>
					<SelectField data={['GET', 'POST']} field={'onInstallWebHook.method'} label={tt('onInstallWebHook.method')}/>
				</CollapseField>
			</Alert>

			<Title order={4} mb="xs">{tt('remoteEventsMap.title')}</Title>
			<Alert icon={<IconInfoCircle />} variant="info2" mb="lg" title={tt('remoteEventsMap.description')}>
				<TextInput
					label={tt('remoteEventsMap.input.label')}
					readOnly
					value={postbackUrl}
					rightSection={(
						<CopyButtonWithTooltip value={postbackUrl} timeout={2000}/>
					)}
				/>
				<br/>
				<Alert title={tt('remoteEventsMap.alert.title')} variant="gradient">
					<Text mb="md">
						{tt('remoteEventsMap.alert.replace')} <strong>{`{external_id}`}</strong> ({tt('remoteEventsMap.alert.replaceIdentifier')} <code>external_id</code>).
					</Text>
					<Text>
						{tt('remoteEventsMap.alert.replace')} <strong>{`{status}`}</strong> {tt('remoteEventsMap.alert.replaceForValues')}
					</Text>
				</Alert>
				<p>{tt('remoteEventsMap.alert.info.1')} <code>pixelId</code> {tt('remoteEventsMap.alert.info.2')} </p>
				<Title order={4} mb="xs">{tt('remoteEventsMap.mapping.title')}</Title>
				<TagsInputField field={'remoteEventsMap.onRegistration'} label={tt('remoteEventsMap.onRegistration')}/>
				<TagsInputField field={'remoteEventsMap.onDeposit'} label={tt('remoteEventsMap.onDeposit')}/>
			</Alert>
		</Card>
	);
}

