 import * as Sentry from "@sentry/react";

if(import.meta.env.PROD) {
	Sentry.init({
		dsn: "https://6ff6eb6cc7510db8a8ee4b8fe802ad15@o4508257291599872.ingest.de.sentry.io/4508319417761872",
		integrations: [
			Sentry.browserTracingIntegration(),
		],
		// Tracing
		tracesSampleRate: 1.0, //  Capture 100% of the transactions
		// Установите 'tracePropagationTargets' для управления тем, для каких URL-адресов должна быть включена распределенная трассировка
		tracePropagationTargets: ["localhost"]
	});
}
