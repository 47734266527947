import {useState} from "react";
import {usePromise} from "@hooks/usePromise.ts";
import {YoApiClient} from "@engine/yoApiClient.tsx";
import {useUpdateEffect} from "usehooks-ts";
import {UseDefaultsTableStateStore} from "./useDefaultsTableState.tsx";
import useTeams from "../../Pages/Teams/Context/useTeams.ts";

interface iUseYoApi {
	defaultSort?: string;
	apiClient: YoApiClient;
	defaults?: UseDefaultsTableStateStore;
	forceQuery?: any;
	requestParams?: any;
}

export function useYoApi({apiClient, defaults, forceQuery = {}, defaultSort = '-_id', requestParams = {}}: iUseYoApi) {
	const { activeTeam } = useTeams();
	const [limit, setLimit] = useState(20);
	const [offset, setOffset] = useState(defaults?.offset ?? 0);
	const [sort, setSort] = useState(defaultSort);
	const [query, setQuery] = useState(() => defaults?.apiQuery ?? {});

	useUpdateEffect(() => setOffset(0), [limit]);
	useUpdateEffect(() => defaults?.storeOffset(offset), [offset]);

	const promise = usePromise(() => {
		let buildedQuery = {...query, team: activeTeam || undefined};
		if(forceQuery) buildedQuery = {...buildedQuery, ...forceQuery};
		return apiClient.fetchList({
			offset,
			sort: sort || defaultSort,
			query: buildedQuery,
			limit,
			requestParams
		});
	}, [offset, sort, limit, JSON.stringify(query), JSON.stringify(forceQuery), activeTeam as string]);
	const {items = [], count = 0} = promise.data || {};

	return {
		isLoading: promise.isLoading,
		isError: promise.isError,
		errData: promise.errData,
		setOffset,
		setSort,
		setQuery,
		setLimit,
		count,
		limit,
		items,
		offset,
		fetch: promise.run
	}
}

export function useYoApiMantinePaginationConnector(data: ReturnType<typeof useYoApi>) {
	return {
		value: Math.ceil(data.offset / data.limit) + 1,
		total: Math.ceil(data.count / data.limit),
		onChange: (v: number) => {
			data.setOffset((v - 1) * data.limit);
		}
	}
}
