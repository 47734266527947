import {Route} from "wouter";
import {ListWebApps} from "./listWebApps.tsx";
import {ViewWebApps} from "./view";
import MenuManager from "@engine/MenuManager.ts";
import {IconApps} from "@tabler/icons-react";

const baseUrl = '/webApps';

MenuManager.RegisterItem({
	path: baseUrl,
	key: 'WebApps',
	name: 'Мои PWA',
	nameKey: 'menuItems.webApps',
	icon: <IconApps/>,
	requiredRights: ['webApp']
})

export function WebAppsRouter(props: { basePath?: string }) {
	return (
		<Route path={props.basePath || baseUrl} nest>
			<Route path={'/'} component={() => <ListWebApps/>}/>
			<Route path={'/archive'} component={() => <ListWebApps showArchived/>}/>
			<Route path={'/v/:id'} component={ViewWebApps}/>
		</Route>
	);
}
