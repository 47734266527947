import {useScopedLocale} from "../../../../locale";
import {useEffect, useState} from "react";
import {Alert, Anchor, Badge, Button, Card, Flex, Group, Loader, Text, TextInput} from "@mantine/core";
import {Link} from "wouter";
import {DomainsApiClient} from "../../../Domains/api.ts";
import {CopyButtonWithTooltip} from "../../../../utils/copyButtonWithTooltip.tsx";
import {CopyDomainMenu} from "../../copyDomainMenu.tsx";

const domainsApi = new DomainsApiClient();
export function LinkedDomainInfo(props: { domain?: string }) {
	const tt = useScopedLocale('webApps.view.DomainTab.LinkedDomainInfo.');
	const [data, setData] = useState<any>();
	const [isLoading, setLoading] = useState(false);

	function fetch() {
		if (!props.domain) return Promise.resolve();
		return domainsApi.fetchItem(props.domain as string).then(res => {
			setData(res.item);
		});
	}

	useEffect(() => {
		if (!props.domain) return;
		setLoading(true);
		fetch().then(() => {
			setLoading(false);
		});
	}, [props.domain]);

	useEffect(() => {
		let timer: any;
		function updateFn() {
			fetch().finally(() => timer = setTimeout(updateFn, 5000))
		}
		updateFn();
		return () => clearTimeout(timer);
	}, [props.domain]);

	if (!props.domain) return null;

	if (isLoading) return <Loader/>;

	if (!data) return null;
	return (
		<>
			<Card shadow="sm" padding="lg">
				<Group mb="md" justify="space-between">
					<Flex align={'center'}>
						<Anchor href={`https://${data.domain}`} target="_blank" style={{marginRight: '6px'}}>
							{data.domain}
						</Anchor>
						<CopyDomainMenu domain={data.domain}/>
					</Flex>
					{data.state === 'OK' ? (
						<Badge color="green">{tt('state.OK')}</Badge>
					): data.state === 'ERROR' ? (
						<Badge color="red">{tt('state.ERROR')}</Badge>
					): data.state === 'AWAIT_NS_UPDATE' ? (
						<Badge color="yellow">{tt('state.AWAIT_NS_UPDATE')}</Badge>
					): data.state === 'BUY_IN_PROGRESS' ? (
						<Badge color="yellow">{tt('state.BUY_IN_PROGRESS')}</Badge>
					): data.state === 'UNKNOWN' ? (
						<Badge color="yellow">{tt('state.UNKNOWN')}</Badge>
					): (
						<Badge color="blue">{data.state}</Badge>
					)}
					{/*{data.buyJob && (
						<Badge
							color={data.buyJob?.state === 'ERROR' ? 'red' : data.buyJob?.state === 'IN_PROGRESS' ? 'yellow' : 'green'}>
							{data.buyJob?.state === 'ERROR' ? tt('state.ERROR') : data.buyJob?.state === 'IN_PROGRESS' ? tt('state.IN_PROGRESS') : tt('state.DEFAULT')}
						</Badge>
					)}*/}
				</Group>

				{data.state === 'OK' ? (
					<div>Domain is ready for use</div>
				): data.state === 'ERROR' ? (
					<Alert title={tt('state.ERROR')} color="red" mt="md">
						{data.errReasonCode}
					</Alert>
				): data.state === 'AWAIT_NS_UPDATE' ? (
					<Alert color={'yellow'} title={tt('state.AWAIT_NS_UPDATE.title')}>
						{data.nsRecords.map((record: string, index: number) => (
							<TextInput
								key={index}
								label={tt('state.AWAIT_NS_UPDATE.item').replace('{{i}}', String(index+1))}
								value={record}
								readOnly
								rightSection={(
									<CopyButtonWithTooltip value={record}/>
								)}
							/>
						))}
					</Alert>
				): data.state === 'BUY_IN_PROGRESS' ? (
					<Alert color={'yellow'} title={tt('state.BUY_IN_PROGRESS')}>
						{tt('state.BUY_IN_PROGRESS.info')}
					</Alert>
				): data.state === 'UNKNOWN' ? null: (
					<Badge color="blue">{data.state}</Badge>
				)}

				{data.buyJob && (
					<>
						<Text>Статус операции: {data.buyJob.subStatus}</Text>
						{data.buyJob.state === 'ERROR' && (
							<Alert title={tt('state.ERROR')} color="red" mt="md">
								{data.buyJob.errReason}
							</Alert>
						)}
					</>
				)}

				<Group mt="md">
					<Button
						variant="secondary"
						component={Link}
						href={`~/domains/v/${data._id}`}
						fullWidth
					>
						{tt('goToDomainPage.label')}
					</Button>

				</Group>
			</Card>
		</>
	)
}
