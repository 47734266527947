import {TextInput, TextInputProps} from "@mantine/core";
import {useYoProvider} from "../index.tsx";
import {useEffect, useState} from "react";


export function InputField({field, validateFn, ...otherProps}: { field: string, validateFn?: (value: string) => boolean } & Omit<TextInputProps, 'value' | 'onChange' | 'onBlur'>) {
	const ctx = useYoProvider<string>(field);
	const [value, setValue] = useState(ctx.value)

	function onChange(e: any) {
		setValue(e.target.value);
	}

	useEffect(() => {
		setValue(ctx.value);
	}, [ctx.value]);

	const readOnly = ctx.bypassProps.readOnly ?? otherProps.readOnly;
	let error = undefined;
	if(validateFn) {
		try {
			const result = validateFn(value);
			if(!result) error = '';
		} catch (err) {
			if(err instanceof Error) {
				error = err.message
			} else {
				error = 'unknown Error';
			}
		}
	}

	return <TextInput
		value={value}
		onChange={onChange}
		onBlur={e => ctx.onChange(e.target.value)}
		error={error}
		{...otherProps}
		readOnly={readOnly}
	/>;
}

