import {Anchor, Badge, Button} from "@mantine/core";
import {Link} from "wouter";
import {useYoApi} from "@hooks/useYoApi.tsx";
import {DomainsApiClient} from "./api.ts";
import {Header} from "@components/Header";
import {useMemo} from "react";
import {
	MantineReactTable,
	useMantineReactTable,
	MRT_ColumnDef
} from "mantine-react-table";
import {useMRTYoApiDataAdapter} from "@hooks/useMRTYoApiDataAdapter.tsx";
import {useMRTState} from "@hooks/useMRTState.tsx";
import {useDefaultsTableState} from "@hooks/useDefaultsTableState.tsx";
import {useLocale} from "../../locale";
import {useUser} from "@engine/UserContext.tsx";
import {viewOwnerColl} from "../../utils/viewOwnerColl.tsx";

const api = new DomainsApiClient()

interface IItem {
	_id: string;
	comment: string;
}

export function ListDomains() {
	const {user} = useUser();
	const defaults = useDefaultsTableState();
	const t = useLocale();

	const createTableState = useMRTState<IItem>({
		visibilityLocalStorageKey: 'ui.Domains.columnVisibility',
		defaults
	});

	const columns = useMemo(() => {
		const cols =  [{
			accessorKey: 'domain',
			header: t('domainsPage.list.table.domainName'),
			Cell: (props) => (
				<Anchor
					target="_blank"
					href={`https://${props.cell.getValue<string>()}`}
				>
					{`${props.cell.getValue<string>()} ${props.row.original.comment ? ` (${props.row.original.comment})` : ''}`}
				</Anchor>
			)
		}, {
			accessorKey: 'registrar',
			header: t('domainsPage.list.table.registrar'),
		}, {
			accessorKey: 'buyJob',
			header: t('domainsPage.list.table.state'),
			enableSorting: false,
			enableColumnActions: false,
			Cell: (props) => {
				if (!props.cell.getValue()) return <Badge
					color={'green'}>{t('domainsPage.list.table.state.ADDED')}</Badge>;
				const {state} = props.cell.getValue<{ state: string }>();
				if (state === 'WAIT') return <Badge color={'red'}>{t('domainsPage.list.table.state.ERROR')}</Badge>;
				if (state === 'IN_PROGRESS') return <Badge
					color={'blue'}>{t('domainsPage.list.table.state.IN_PROGRESS')}</Badge>;
				if (state === 'DONE') return <Badge color={'green'}>{t('domainsPage.list.table.state.DONE')}</Badge>;
				if (state === 'ERROR') return <Badge color={'red'}>{t('domainsPage.list.table.state.ERROR')}</Badge>;
				return <Badge color={'red'}>{t('domainsPage.list.table.state.UNKNOWN')}</Badge>;
			}
		}, {
			accessorKey: 'actions',
			header: t('domainsPage.list.table.actions'),
			enableSorting: false,
			enableColumnActions: false,
			Cell: (props) => (
				<>
					<Button component={Link} href={`/v/${props.row.original._id}`} variant="primary" size={'compact-sm'}>{t('domainsPage.list.table.actions.edit')}</Button>
				</>
			)
		}] as MRT_ColumnDef<IItem>[];
		if(user.isSuperAdmin) cols.unshift(viewOwnerColl as MRT_ColumnDef<IItem>);
		return cols;
	}, []);

	const data = useYoApi({ apiClient: api, defaults });
	const tableState = createTableState({
		data,
		columns
	});
	useMRTYoApiDataAdapter({
		apiClient: data,
		tableParams: tableState.adapterProps,
		defaults,
	});
	const table = useMantineReactTable<IItem>(tableState);

	return <>
		<Header
			title={t('domainsPage.list.title')}
			items={(
				<>
				</>
			)}
		/>
		<MantineReactTable
			table={table}
		/>
	</>;
}
