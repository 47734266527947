import {createContext, ReactNode, useContext, useState} from "react";
import {Button, Grid, Modal, Textarea} from "@mantine/core";
import {useYoProvider} from "@components/YoContextEditor";
import ky from "ky";
import {displayErrorNotification} from "@serviceComponents/displayErrorNotification.tsx";
import {useLocaleContext} from "../../locale";

const Ctx = createContext<{ open: () => void }>({} as any)

export function YoGenerateContentProvider(props: { children: ReactNode, field: string, contentType: string }) {
	const {selectedLocale} = useLocaleContext();
	const [isOpen, setOpen] = useState(false);
	const [lockClose, setLockClose] = useState(false);

	const [disabled, setDisabled] = useState(false);
	const [generatedResult, setGeneratedResult] = useState<{original: string, translated: string}>({} as any);
	const [prompt, setPrompt] = useState<string>('');

	const yoCtx = useYoProvider(props.field);
	const appNameCtx = useYoProvider('content.name');

	async function runGenerate() {
		setLockClose(true);
		setDisabled(true);
		try {
			const result = await ky.post('/api/v1/utils/generate', {
				json: {
					appName: appNameCtx.value,
					language: yoCtx.bypassProps.lang,
					contentType: props.contentType,
					userLanguage: selectedLocale,
					userPrompt: prompt
				}
			}).json<{ status: boolean, original: string, translated: string }>();
			setGeneratedResult({original: result.original, translated: result.translated});
		} catch (err) {
			return displayErrorNotification(err);
		} finally {
			setLockClose(false);
			setDisabled(false);
		}
	}

	function closeModal() {
		if (lockClose) return;
		return setOpen(false);
	}

	function insertData() {
		yoCtx.onChange(generatedResult.original);
		setGeneratedResult({} as any);
		setPrompt('');
		setOpen(false);

	}

	return <Ctx.Provider
		value={{
			open: () => setOpen(true)
		}}
	>
		{props.children}
		<Modal opened={lockClose ? true : isOpen} onClose={closeModal} title="Генерация" size={'xl'}>
			<Grid>
				<Grid.Col span={generatedResult ? 6 : 12}>
					<Textarea
						disabled={disabled}
						autosize
						minRows={4}
						label={'Промпт'}
						description={'Напиши промпт для генерации контента'}
						value={prompt}
						onChange={e => setPrompt(e.currentTarget.value)}
					/>
					<Button fullWidth disabled={disabled} onClick={runGenerate}>Генерировать</Button>
				</Grid.Col>
				{generatedResult.original && (
					<Grid.Col span={6}>
						<Textarea
							value={generatedResult.translated}
							readOnly
							autosize
							minRows={4}
							label={'Результат генерации (переведённое превью)'}
						/>
						<Textarea
							value={generatedResult.original}
							readOnly
							autosize
							minRows={4}
							label={'Результат генерации (оригинал)'}
							description={'Данный текст будет использован как результат генерации'}
						/>
						<Button fullWidth disabled={disabled} onClick={insertData}>Использовать этот результат</Button>
					</Grid.Col>
				)}
			</Grid>
		</Modal>
	</Ctx.Provider>;
}


export function useGenerateContent() {
	return useContext(Ctx);
}
