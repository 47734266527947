import {createContext, Dispatch, ReactNode, SetStateAction, useEffect, useMemo, useState} from "react";
import {Card, Center, Loader, Title} from "@mantine/core";
import {TeamsApiClient, type TMyTeam} from "../api.ts";
import CreateTeamForm from "../Components/CreateTeamForm.tsx";

type TTeamsContext = {
    myTeams: TMyTeam[],
    activeTeam: string | null,
    setActiveTeam: Dispatch<SetStateAction<string | null>>,
}

export const TeamsContext = createContext<TTeamsContext>({} as TTeamsContext);

const api = new TeamsApiClient();

export function TeamsContextProvider({ children }: { children: ReactNode }) {
    const [activeTeam, setActiveTeam] = useState<string | null>(localStorage.getItem("activeTeam") || null);
    const [myTeams, setMyTeams] = useState<TMyTeam[] | null>(null);

    const loadMyTeams = async () => {
        try {
            const res = await api.getMyTeams();
            if (!res.status) throw res.message;
            setMyTeams(res.items);
            if (!activeTeam) {
                if (res.items[0]) {
                    setActiveTeam(res.items[0]._id)
                }
            } else {
                if (!res.items.find(e => e._id === activeTeam)) {
                    if (res.items[0]) {
                        setActiveTeam(res.items[0]._id)
                    }
                }
            }
        } catch (e) {
            setMyTeams([]);
        }
    }

    useEffect(() => {
        void loadMyTeams();
    }, []);

    useEffect(() => {
        if (activeTeam) {
            localStorage.setItem("activeTeam", activeTeam);
        } else {
            localStorage.removeItem("activeTeam");
        }
    }, [activeTeam]);

    const value: TTeamsContext = useMemo(() => ({
        myTeams: myTeams as TMyTeam[],
        activeTeam,
        setActiveTeam
    }), [activeTeam, setActiveTeam, myTeams]);

    if (!myTeams) {
        return <Loader />
    }

    if (myTeams.length === 0) return (
        <Center h="60dvh">
            <Card>
                <Title order={3} mb="sm">
                    Create team
                </Title>
                <CreateTeamForm onSuccess={loadMyTeams} />
            </Card>
        </Center>
    );

    return (
        <TeamsContext.Provider value={value}>
            {children}
        </TeamsContext.Provider>
    );
}

