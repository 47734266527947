import {YoContextEditor} from "@components/YoContextEditor";
import {InputField} from "@components/YoContextEditor/components/inputField.tsx";
import {Header} from "@components/Header";
import {Button} from "@mantine/core";
import {notifications} from "@mantine/notifications";
import {useSiteSettings} from "@hooks/UseSiteSettings.tsx";
import {displayErrorNotification} from "@serviceComponents/displayErrorNotification.tsx";

/*MenuManager.RegisterItem({
	path: '/system/settings/cloudflare',
	parent: 'system',
	key: 'cloudflare',
	name: 'Настройки cloudflare',
	nameKey: 'menuItems.system.cloudflareSettings',
	icon: <IconBrandCloudflare/>
})*/

export function CloudflareSettingsPage() {
	const controller = useSiteSettings('domains.cloudflare.');

	function saveSettings() {
		return controller.saveChanges()
			.then(() => notifications.show({message: 'Настройки успешно сохранены'}))
			.catch(displayErrorNotification)
	}

	return (
		<YoContextEditor {...controller.controller}>
			<Header title={'Настройки cloudflare'} items={(
				<>
					<Button onClick={saveSettings}>Сохранить настройки</Button>
				</>
			)}/>
			<InputField field={'apiToken'} label={'Токен доступа'}/>
			<InputField field={'currency'} label="currency"/>
		</YoContextEditor>
	);
}
