import {ReactNode} from "react";
import {AppShell, Image, ScrollArea} from "@mantine/core";
import {UserMenu} from "./UserMenu/UserMenu.tsx";
import {useLocalStorage} from "@mantine/hooks";
import '../assets/global.css';
import logoSvgUrl from '../assets/svg/logo.svg?url';
import NavLinksList from "./NavLinksList";
import ActiveTeamSelect from "../Pages/Teams/Components/ActiveTeamSelect.tsx";


export function Shell({children}: {children: ReactNode}) {
	const [mobileOpened] = useLocalStorage({key: 'ui.mobileOpened', defaultValue: false});
	const [desktopOpened] = useLocalStorage({key: 'ui.desktopOpened', defaultValue: true});

	return (
		<AppShell
			/*header={{ height: 60 }}*/
			navbar={{
				width: 300,
				breakpoint: 'sm',
				collapsed: { mobile: !mobileOpened, desktop: !desktopOpened },
			}}
			padding="md"
		>
			{/*<AppShell.Header>
				<Group h="100%" px="md">
					<Burger opened={mobileOpened} onClick={() => toggleMobile(!mobileOpened)} hiddenFrom="sm" size="sm" />
					<Burger opened={desktopOpened} onClick={() => toggleDesktop(!desktopOpened)} visibleFrom="sm" size="sm" />
					<h3>YoFramework SA</h3>
				</Group>
			</AppShell.Header>*/}
			<AppShell.Navbar >
				<AppShell.Section style={{ borderBottom: '1px solid var(--app-shell-border-color)' }}>
					<Image src={logoSvgUrl} alt="logo" />
					<ActiveTeamSelect />
				</AppShell.Section>
				<AppShell.Section grow my="md" component={ScrollArea}>
					<NavLinksList />
				</AppShell.Section>
				<AppShell.Section style={{ borderTop: '1px solid var(--app-shell-border-color)' }}>
					<UserMenu/>
				</AppShell.Section>
			</AppShell.Navbar>
			<AppShell.Main>
				{children}
			</AppShell.Main>
		</AppShell>
	);
}
