import {InputField} from "@components/YoContextEditor/components/inputField.tsx";
import {Card, Menu, Stack, Title} from "@mantine/core";
import {IconBubble} from "@tabler/icons-react";
import {TranslateFieldMenuItem} from "@components/SmartMenuItems/translateFieldMenuItem.tsx";
import {CheckboxField} from "@components/YoContextEditor/components/checkboxField.tsx";
import {TextareaField} from "@components/YoContextEditor/components/textareaField.tsx";
import {GenerateFieldMenuItemTrigger} from "@components/SmartMenuItems/generateFieldMenuItemTrigger.tsx";
import {TagsInputField} from "@components/YoContextEditor/components/tagsInputField.tsx";
import {YoGenerateContentProvider} from "@components/YoAi/yoGenerateContentProvider.tsx";
import {MoveFromBaseMenuItem} from "@components/SmartMenuItems/moveFromBase.tsx";
import {useScopedLocale} from "../../../../locale";
import {CollapseField} from "@components/YoContextEditor/components/CollapseField.tsx";

export function DescriptionTab() {
	const tt = useScopedLocale('webApps.views.DescriptionTab.');
	const ct = useScopedLocale('webApps.views.ContentTab.');

	return (
		<Card withBorder>
			<Title order={4} mb="lg">
				{ct('tabsList.description')}
			</Title>
			<Card variant="section">
				<Stack>
					<InputField
						field={'content.name'}
						label={tt('name.label')}
						rightSection={(
							<Menu shadow="md" width={250}>
								<Menu.Target>
									<IconBubble/>
								</Menu.Target>
								<Menu.Dropdown>
									<TranslateFieldMenuItem field={'content.name'}/>
									<MoveFromBaseMenuItem field={'content.name'}/>
								</Menu.Dropdown>
							</Menu>
						)}
					/>
					<CheckboxField
						field={'content.isVerify'}
						label={'Приложение верифицировано'}
						description={'При установке этого параметра рядом с названием приложения появляется знак верифицированного приложения'}
					/>
					<InputField
						field={'content.owner'}
						label={tt('content.owner.label')}
						rightSection={(
							<Menu shadow="md" width={250}>
								<Menu.Target>
									<IconBubble/>
								</Menu.Target>

								<Menu.Dropdown>
									<TranslateFieldMenuItem field={'content.owner'}/>
									<MoveFromBaseMenuItem field={'content.owner'}/>
								</Menu.Dropdown>
							</Menu>
						)}
					/>
					<YoGenerateContentProvider contentType={'description'} field={'content.description'}>
						<TextareaField
							autosize
							minRows={3}
							field={'content.description'}
							label={tt('content.description.label')}
							rightSection={(
								<Menu shadow="md" width={250}>
									<Menu.Target>
										<IconBubble/>
									</Menu.Target>

									<Menu.Dropdown>
										<TranslateFieldMenuItem field={'content.description'}/>
										<GenerateFieldMenuItemTrigger/>
										<MoveFromBaseMenuItem field={'content.description'}/>
									</Menu.Dropdown>
								</Menu>
							)}
						/>
					</YoGenerateContentProvider>
					<InputField
						field={'content.updatedDate'}
						label={tt('content.updatedDate.label')}
						rightSection={(
							<Menu shadow="md" width={250}>
								<Menu.Target>
									<IconBubble/>
								</Menu.Target>

								<Menu.Dropdown>
									<TranslateFieldMenuItem field={'content.updatedDate'}/>
									<MoveFromBaseMenuItem field={'content.updatedDate'}/>
								</Menu.Dropdown>
							</Menu>
						)}
					/>
					<TagsInputField field={'content.tags'} label={tt('content.tags.label')}/>
					<h4>
						{tt('content.whatsNew.title')}
					</h4>
					<CheckboxField field={'content.whatsNew.enabled'} label={tt('content.whatsNew.enabled')}/>
					<CollapseField field={'content.whatsNew.enabled'}>
						<TextareaField
							autosize
							minRows={3}
							field={'content.whatsNew.content'}
							label={tt('content.whatsNew.content')}
							rightSection={(
								<Menu shadow="md" width={250}>
									<Menu.Target>
										<IconBubble/>
									</Menu.Target>

									<Menu.Dropdown>
										<TranslateFieldMenuItem field={'content.whatsNew.content'}/>
										<MoveFromBaseMenuItem field={'content.whatsNew.content'}/>
									</Menu.Dropdown>
								</Menu>
							)}
						/>
					</CollapseField>
				</Stack>
			</Card>
		</Card>
	);
}
