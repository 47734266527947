import {WebAppsApiClient} from "./api.ts";
import {usePromise} from "@hooks/usePromise.ts";
import {MultiSelect, MultiSelectProps, Select, SelectProps} from "@mantine/core";

const api = new WebAppsApiClient();

interface iSelectWebAppProps extends SelectProps {
	hideAll?: boolean;
}

function useData(props: {hideAll?: boolean}) {
	const promise = usePromise(() => api.fetchList({limit: 1000}).then(res => res.items.map((el: any) => {
		return {
			label: `${el.name} - ${el.state}`,
			value: el._id
		}
	})), []);

	const data = (promise.data ?? []);
	if(!props.hideAll) data.unshift({label: 'Все', value: 'any'});

	return {
		items: data,
	}
}

export function SelectWebApp({hideAll, ...otherProps}: iSelectWebAppProps) {
	const {items} = useData({hideAll});

	return (
		<Select
			data={items}
			{...otherProps}
		/>
	);
}

export function MultiSelectWebApp({hideAll, ...otherProps}: {hideAll?: boolean} & MultiSelectProps) {
	const {items} = useData({hideAll});

	return (
		<MultiSelect
			data={items}
			{...otherProps}
		/>
	)
}
