import {ActionIcon, Avatar, Button, Collapse, Flex, Modal} from "@mantine/core";
import {useYoApi} from "@hooks/useYoApi.tsx";
import {AvatarGalleryApiClient} from "./api.ts";
import {Header} from "@components/Header";
import {useMemo, useState} from "react";
import {MantineReactTable, MRT_ColumnDef, useMantineReactTable} from "mantine-react-table";
import {useMRTYoApiDataAdapter} from "@hooks/useMRTYoApiDataAdapter.tsx";
import {useMRTState} from "@hooks/useMRTState.tsx";
import {useDefaultsTableState} from "@hooks/useDefaultsTableState.tsx";
import {getFileLink} from "../../utils/getFileLink.tsx";
import {AvatarAgeSelect, AvatarGenderSelect, AvatarRaceSelect} from "./AvatarTypesSelect.tsx";
import {ImageUploader} from "@YoUi/imageUploader.tsx";
import {displayErrorNotification} from "@serviceComponents/displayErrorNotification.tsx";
import {IconTrash} from "@tabler/icons-react";
import {ConfirmDeleteModal} from "@modals/confirmModal.tsx";

const api = new AvatarGalleryApiClient()

interface IItem {
	_id: string;
}

function AvatarUploader() {
	const [data, setData] = useState<any>({});
	const [uploadedImages, setUploadedImages] = useState<string[]>([]);


	async function onDrop(meta: any) {
		setUploadedImages(prev => [...prev, meta._id as string]);
		await api.createItem({...data, file: meta._id}).catch(displayErrorNotification);
	}

	return (
	<>
		<AvatarGenderSelect
			required
			withAsterisk
			value={data.gender}
			onChange={val => setData({...data, gender: val})}
		/>
		<AvatarRaceSelect
			required
			withAsterisk
			value={data.race}
			onChange={val => setData({...data, race: val})}
		/>
		<AvatarAgeSelect
			required
			withAsterisk
			value={data.age}
			onChange={val => setData({...data, age: val})}
		/>
		<Collapse in={data.gender && data.race && data.age}>
			<ImageUploader onDrop={onDrop}/>
		</Collapse>

		<Avatar.Group>
			{uploadedImages.map(img => (
				<Avatar key={img} src={getFileLink(img)}/>
			))}
		</Avatar.Group>
	</>
	);
}

export function ListAvatarGallery() {
	const [isOpen, setIsOpen] = useState(false);

	const defaults = useDefaultsTableState();

	const createTableState = useMRTState<IItem>({
		visibilityLocalStorageKey: 'ui.AvatarGallery.columnVisibility',
		defaults
	});

	async function tryRemove(value: string) {
		if(!await ConfirmDeleteModal()) return false;
		return api.deleteItem(value).then(data.fetch).catch(displayErrorNotification);
	}

	const columns = useMemo<MRT_ColumnDef<IItem>[]>(() => [{
		accessorKey: 'file',
		header: 'Фото',
		size: 100,
		Cell: props => (
			<Flex align={'center'}>
				<Avatar src={getFileLink(props.cell.getValue<string>())} alt="Common mammoth" />
			</Flex>
		)
	}, {
		accessorKey: 'gender',
		header: 'Пол',
		filterVariant: 'select',
		yoFilerType: 'equals',
		mantineFilterSelectProps: {data: ['MALE', 'FEMALE']},
	}, {
		accessorKey: 'race',
		header: 'Раса',
		filterVariant: 'select',
		yoFilerType: 'equals',
		mantineFilterSelectProps: {data: ['ASIAN', 'EUROPEAN', 'AFRICAN_AMERICAN', 'LATINO', 'ARAB', 'INDIAN']},
	}, {
		accessorKey: 'age',
		header: 'Возраст',
		filterVariant: 'select',
		yoFilerType: 'equals',
		mantineFilterSelectProps: {data: ['YOUNG_ADULT', 'ADULT', 'ELDERLY']},
	}, {
		accessorKey: 'actions',
		header: 'actions',
		enableSorting: false,
		enableColumnActions: false,
		Cell: (props) => (
			<>
				<ActionIcon color={'red'} onClick={() => tryRemove(props.row.original._id)}>
					<IconTrash/>
				</ActionIcon>
			</>
		)
	}], []);

	const data = useYoApi({apiClient: api, defaults});
	const tableState = createTableState({
		data,
		columns
	});
	useMRTYoApiDataAdapter({
		apiClient: data,
		tableParams: tableState.adapterProps,
		defaults,
	});
	const table = useMantineReactTable<IItem>(tableState);

	return <>
		<Header
			title={'AvatarGallery'}
			items={(
				<>
					<Button size={'compact-sm'} onClick={() => setIsOpen(true)}>
						Upload
					</Button>
				</>
			)}
		/>
		<MantineReactTable table={table}/>
		<Modal title={'Upload avatars'} opened={isOpen} onClose={() => {
			setIsOpen(false);
			data.fetch();
		}} size={'xl'}>
			<AvatarUploader />
		</Modal>
	</>;
}
