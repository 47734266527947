import {Button, Fieldset, Grid, Menu, SimpleGrid, Stack, Title} from "@mantine/core";
import {ImageUploaderField} from "@components/YoContextEditor/components/imageUploaderField.tsx";
import {InputField} from "@components/YoContextEditor/components/inputField.tsx";
import {TextareaField} from "@components/YoContextEditor/components/textareaField.tsx";
import {NumberField} from "@components/YoContextEditor/components/numberField.tsx";
import {IconBubble} from "@tabler/icons-react";
import {TranslateFieldMenuItem} from "@components/SmartMenuItems/translateFieldMenuItem.tsx";
import {useScopedLocale} from "../../../../locale";
import {CollapseField} from "@components/YoContextEditor/components/CollapseField.tsx";
import {CheckboxField} from "@components/YoContextEditor/components/checkboxField.tsx";
import {RenderFuncField} from "@components/YoContextEditor/components/renderFuncField.tsx";
import {useYoProvider} from "@components/YoContextEditor";
import {pickRandomAvatar, showAvatarPicker} from "./showAvatarPicker.tsx";
import {displayErrorNotification} from "@serviceComponents/displayErrorNotification.tsx";

export function ReviewForm() {
	const tt = useScopedLocale('webApps.view.ReviewForm.');
	const metaCtx = useYoProvider<any>('userMeta', {defaultValue: {}});
	return (
		<Grid>
			<Grid.Col span={3}>
				<Fieldset>
					<Title order={4}>{tt('avatar')}</Title>
					<ImageUploaderField field={'avatar'} hideDescriptionWithImage/>
					<RenderFuncField field={'avatar'}>
						{ctx => (
							<SimpleGrid cols={2}>
								<Button onClick={() => showAvatarPicker({
									gender: metaCtx.value.gender,
									race: metaCtx.value.race,
									age: metaCtx.value.age
								}).then(val => ctx.onChange(val))}>
									Avatar gallery
								</Button>
								<Button onClick={() => pickRandomAvatar({
									gender: metaCtx.value.gender,
									race: metaCtx.value.race,
									age: metaCtx.value.age
								}).then(val => ctx.onChange(val)).catch(displayErrorNotification)}>
									I'll be lucky
								</Button>
							</SimpleGrid>
						)}
					</RenderFuncField>
				</Fieldset>
			</Grid.Col>
			<Grid.Col span={9}>
				<Stack>
					<InputField
						field={'name'}
						label={tt('name')}
						withAsterisk
						rightSection={(
							<Menu shadow="md" width={250}>
								<Menu.Target>
									<IconBubble/>
								</Menu.Target>
								<Menu.Dropdown>
									<TranslateFieldMenuItem field={'name'} upProvider={1}/>
								</Menu.Dropdown>
							</Menu>
						)}
					/>
					<TextareaField
						field={'text'}
						autosize
						label={tt('text')}
						minRows={3}
						withAsterisk
						rightSection={(
							<Menu shadow="md" width={250}>
								<Menu.Target>
									<IconBubble/>
								</Menu.Target>
								<Menu.Dropdown>
									<TranslateFieldMenuItem field={'text'} upProvider={1}/>
								</Menu.Dropdown>
							</Menu>
						)}
					/>
					<NumberField field={'rating'} min={1} max={5} allowDecimal={false} label={tt('rating')} withAsterisk/>
					<NumberField field={'reviewRating'} label={tt('reviewRating')} withAsterisk/>
					<InputField
						field={'commentDate'}
						label={tt('commentDate')}
						rightSection={(
							<Menu shadow="md" width={250}>
								<Menu.Target>
									<IconBubble/>
								</Menu.Target>
								<Menu.Dropdown>
									<TranslateFieldMenuItem field={'commentDate'} upProvider={1}/>
								</Menu.Dropdown>
							</Menu>
						)}
					/>
					<br/>
					<CheckboxField field={'showOwnerResponse'} label={tt('ownerResponse.show')}/>
					<CollapseField field={'showOwnerResponse'}>
						<TextareaField autosize minRows={3} field={'ownerResponse'} label={tt('ownerResponse.content')}/>
						<InputField field={'ownerResponseDate'} label={tt('ownerResponse.date')}/>
					</CollapseField>
				</Stack>
			</Grid.Col>
		</Grid>
	);
}
