import {useEffect, useState} from "react";
import {Link, useParams} from "wouter";
import {usePromise} from "@hooks/usePromise.ts";
import {YoContextEditor} from "@components/YoContextEditor";
import {Button, Tabs} from "@mantine/core";
import {notifications} from "@mantine/notifications";

import {WebAppsApiClient} from "../api.ts";
import {Header} from "@components/Header";
import {ConfirmDeleteModal} from "@modals/confirmModal.tsx";
import {useNavigate} from "@hooks/useNavigate.tsx";
import {
	IconActivityHeartbeat,
	IconAnalyze,
	IconBrandPushbullet,
	IconHome,
	IconLetterCase,
	IconShare,
	IconShieldCheck,
	IconTestPipe,
	IconWorldWww
} from "@tabler/icons-react";
import {TrackerTab} from "./trackerTab.tsx";
import {AnalyticsTab} from "./analyticsTab.tsx";
import {PushTab} from "./pushTab.tsx";
import {ContentTab} from "./contentTab";
import {PublishTab} from "./publishTab.tsx";
import {useLocalStorage} from "@mantine/hooks";
import {displayErrorNotification} from "@serviceComponents/displayErrorNotification.tsx";
import {GeneralTab} from "./generalTab.tsx";
import {ABTab} from "./ABTab.tsx";
import {CloakingSettingsPanel} from "./cloakingSettingsTab.tsx";
import {useScopedLocale} from "../../../locale";
import {DomainsTab} from "./domainsTab/index.tsx";
import {useUser} from "@engine/UserContext.tsx";

const api = new WebAppsApiClient();

export function ViewWebApps() {
	const tt = useScopedLocale('webApps.view.');

	const navigate = useNavigate();
	const params = useParams<{id: string}>();
	const {user} = useUser();
	const [tab, setTab] = useLocalStorage({key: `ui.webApps.${params.id}.tab`, defaultValue: 'domain'});
	const promise = usePromise(() => api.fetchItem(params.id), [params.id]);
	const [data, setData] = useState<any>({});
	const [changes, setChanges] = useState({});
	useEffect(() => setData(promise.data?.item ?? {}), [promise.isLoading]);
	const isAllowEdit = true; /*useMemo(() => {
		if(promise.isLoading) return false;
		if(userCtx.user.isSuperAdmin) return true;
		if(promise.data?.item.owner === userCtx.user._id) return true;
		const sharedRecord = promise.data?.item.sharedWith.find((i: any) => i.user._id === userCtx.user._id);
		if(!sharedRecord) return false;
		return sharedRecord.role === 'editor';
	}, [promise.isLoading]);*/


	function trySave() {
		if(!isAllowEdit) return;
		api.saveItem(params.id, changes).then(() => {
			notifications.show({message: tt('saved')});
			setChanges({});
		}).catch(displayErrorNotification)
	}

	async function tryRemove() {
		if(!isAllowEdit) return;
		if(!await ConfirmDeleteModal()) return false;
		return api.deleteItem(params.id).then(() => navigate('/'))
	}
	async function tryForceRemove() {
		if(!await ConfirmDeleteModal()) return false;
		return api.forceDeleteItem(params.id).then(() => navigate('/archive'))
	}

	function saveChangesInUnpublishedApp(newTab: string | null) {
		if(newTab) setTab(newTab);
		if(!isAllowEdit) return;
		if(data.state === 'PUBLISHED') return;
		api.saveItem(params.id, changes).then(() => {
			setChanges({});
		}).catch(displayErrorNotification);
	}

	return (
		<YoContextEditor item={data} setItem={setData} changes={changes} setChanges={setChanges} bypassProps={{hasUnsavedChanges: Object.keys(changes).length, readOnly: !isAllowEdit}}>
			<Header
				backButtonUrl=""
				title={`${tt('title')} «${data.name || tt('unnamed')}»`}
				items={(
					<>
						<Link href={`~/leads?appId=${params.id}`}>
							<Button variant="primary" size={'compact-sm'} color={'green'}>{tt('header.leads')}</Button>
						</Link>
						{isAllowEdit && (
							<>
								<Button variant="primary" size="compact-sm" onClick={trySave}>{tt('header.save')}</Button>
								{!data.archive?.isArchived ? (
									<Button variant="outline" size="compact-sm"  color="white" onClick={tryRemove}>{tt('header.delete')}</Button>
								): user.isSuperAdmin ? (
									<Button variant="outline" size="compact-sm"  color="red" onClick={tryForceRemove}>{tt('header.delete')} (Force)</Button>
								): null}

							</>
						)}
					</>
				)}
			/>
			<Tabs value={tab} keepMounted={false} onChange={saveChangesInUnpublishedApp} variant="pills">
				<Tabs.List grow mb="md">
					<Tabs.Tab value="base" leftSection={<IconHome />}>
						{tt('tabs.list.base')}
					</Tabs.Tab>
					<Tabs.Tab value="domain" leftSection={<IconWorldWww />}>
						{tt('tabs.list.domain')}
					</Tabs.Tab>
					<Tabs.Tab value="tracker" leftSection={<IconShare />}>
						{tt('tabs.list.tracker')}
					</Tabs.Tab>
					<Tabs.Tab value="content" leftSection={<IconLetterCase />}>
						{tt('tabs.list.content')}
					</Tabs.Tab>
					<Tabs.Tab value="analytics" leftSection={<IconAnalyze />}>
						{tt('tabs.list.analytics')}
					</Tabs.Tab>
					<Tabs.Tab value={'push'} leftSection={<IconBrandPushbullet/>}>
						{tt('tabs.list.push')}
					</Tabs.Tab>
					<Tabs.Tab value={'ab'} leftSection={<IconTestPipe/>}>
						{tt('tabs.list.ab')}
					</Tabs.Tab>
					<Tabs.Tab value={'cloaking'} leftSection={<IconShieldCheck/>}>
						{tt('tabs.list.cloaking')}
					</Tabs.Tab>
					<Tabs.Tab value={'publish'} leftSection={<IconActivityHeartbeat/>}>
						{tt('tabs.list.publish')}
					</Tabs.Tab>
				</Tabs.List>
				<Tabs.Panel value="base">
					<GeneralTab/>
				</Tabs.Panel>
				<Tabs.Panel value="domain">
					<DomainsTab/>
				</Tabs.Panel>
				<Tabs.Panel value="tracker">
					<TrackerTab/>
				</Tabs.Panel>
				<Tabs.Panel value="analytics">
					<AnalyticsTab/>
				</Tabs.Panel>
				<Tabs.Panel value='push'>
					<PushTab/>
				</Tabs.Panel>
				<Tabs.Panel value="content">
					<ContentTab/>
				</Tabs.Panel>
				<Tabs.Panel value="publish">
					<PublishTab/>
				</Tabs.Panel>
				<Tabs.Panel value="ab">
					<ABTab/>
				</Tabs.Panel>
				<Tabs.Panel value="cloaking">
					<CloakingSettingsPanel/>
				</Tabs.Panel>
			</Tabs>
		</YoContextEditor>
	);
}
