import {InputField} from "@components/YoContextEditor/components/inputField.tsx";
import {YoContextArrayMap} from "@components/YoContextEditor/YoContextArrayMap.tsx";
import {Button, Card, Fieldset, Flex, SimpleGrid, Text, Title} from "@mantine/core";
import {RenderFuncField} from "@components/YoContextEditor/components/renderFuncField.tsx";
import {CheckboxField} from "@components/YoContextEditor/components/checkboxField.tsx";
import {ReadonlyAreaField} from "@components/YoContextEditor/ReadonlyAreaField.tsx";
import {notEq} from "@components/YoContextEditor/components/CollapseField.tsx";
import {mongoObjectId} from "../../../utils/mongoObjectId.ts";
import {RuleEditorField} from "./components/rules/ruleEditorField.tsx";
import {RulesHeaderField} from "./components/rules/RulesHeaderField.tsx";
import {useScopedLocale} from "../../../locale";

export function TrackerTab() {
	const tt = useScopedLocale('webApps.view.TrackerTab.');

	return (
		<Card withBorder>
			<Title order={3}>{tt('title')}</Title>
			<Card variant="section" mb="lg">
				<Text mb="xs" size="sm">{tt('description.1')}</Text>
				<Text mb="md" size="sm">{tt('description.2')}</Text>
				<YoContextArrayMap field={'alternativeOffersRules'}>
					<Fieldset>
						<RulesHeaderField badgeColor={'gray'} getBadgeContent={ctx => `Вариант №${ctx.bypassProps.__index + 1}`}/>
						<br/>
						<SimpleGrid cols={2}>
							<Flex align={'center'}>
								<CheckboxField field={'enabled'} label={tt('rules.enabled')}/>
							</Flex>
							<InputField field={'comment'} label={tt('rules.comment.label')} description={tt('rules.comment.description')}/>
						</SimpleGrid>
						<ReadonlyAreaField field={'enabled'} eqFn={notEq}>
							<Title order={4}>{tt('rules.conditions.title')}</Title>
							<YoContextArrayMap field={'conditions'}>
								<RulesHeaderField badgeColor={'teal'} getBadgeContent={ctx => `Условие №${ctx.bypassProps.__index + 1}`}/>
								<RuleEditorField/>
								<hr/>
							</YoContextArrayMap>
							<RenderFuncField field={'conditions'}>
								{ctx => (
									<Button variant="secondary" fullWidth disabled={ctx.bypassProps.readOnly} size={'compact-sm'} onClick={() => ctx.push({_id: mongoObjectId(), ruleType: 'language'})}>
										{tt('rules.conditions.button.add')}
									</Button>
								)}
							</RenderFuncField>
							<Title order={4}>{tt('rules.offerLink.title')}</Title>
							<InputField field={'offerLink'} label={tt('rules.offerLink.label')}/>
							<hr/>
						</ReadonlyAreaField>
					</Fieldset>
				</YoContextArrayMap>
				<br/>
				<div>
					<RenderFuncField field={'alternativeOffersRules'}>
						{ctx => (
							<Button variant="secondary" onClick={() => ctx.push({_id: mongoObjectId(), enabled: true})}>
								{tt('rules.offerLink.button.add')}
							</Button>
						)}
					</RenderFuncField>
				</div>
			</Card>
			<Title order={3}>{tt('alternateLink.title')}</Title>
			<Card variant="section">
				<p>{tt('alternateLink.description')}</p>
				<InputField field={'offerLink'} label={tt('alternateLink.label')}/>
			</Card>
		</Card>
	);
}
