import {
	Button,
	Flex,
	Grid,
	Modal,
	NumberInput,
	Textarea,
	Text,
	Checkbox,
	Fieldset,
	TextInput,
	SimpleGrid, Avatar
} from "@mantine/core";
import {useState} from "react";
import {useYoProvider} from "@components/YoContextEditor";
import {useLocaleContext, useScopedLocale} from "../../../../locale";
import ky from "ky";
import {displayErrorNotification} from "@serviceComponents/displayErrorNotification.tsx";
import {mongoObjectId} from "../../../../utils/mongoObjectId.ts";
import {getFileLink} from "../../../../utils/getFileLink.tsx";

export function GenerateReviewsModal(props: { isOpen: boolean, closeModal: () => void }) {
	const tt = useScopedLocale('webApps.view.GenerateReviewsModal.');
	const ctx = useYoProvider();
	const reviewsCtx = useYoProvider('reviews');
	const {selectedLocale} = useLocaleContext();
	const [prompt, setPrompt] = useState<string>('');
	const [lockClose, setLockClose] = useState(false);
	const [amount, setAmount] = useState<number>(5);
	const [disabled, setDisabled] = useState(false);
	const [showTranslated, setShowTranslated] = useState(true);
	const [comments, setComments] = useState<any[]>([]);

	async function runGenerate() {
		setDisabled(true);
		setLockClose(true);
		try {
			const result = await ky.post('/api/v1/utils/generate/reviews', {
				json: {
					appName: ctx.value.content.name,
					description: ctx.value.content.description,
					language: ctx.bypassProps.lang,
					userLanguage: selectedLocale,
					userPrompt: prompt,
					amount
				},
				timeout: 1000 * 60
			}).json<{items: any[]}>();
			setComments(p => [...p, ...result.items])
		} catch (err) {
			displayErrorNotification(err)
		} finally {
			setDisabled(false);
			setLockClose(false);
		}
	}

	function onClose() {
		if(lockClose) return;
		setComments([]);
		setPrompt('');
		props.closeModal();
	}

	function removeReview(index: number) {
		setComments(prev => {
			const arr = prev.slice();
			arr.splice(index,1);
			return arr;
		});

	}

	function addToReviews(index: number) {
		setComments(prev => {
			const arr = prev.slice();
			arr[index].added = true;
			return arr;
		});
		const review = comments[index];
		reviewsCtx.push({
			_id: mongoObjectId(),
			name: review.name?.original,
			rating: Number(review.rating),
			text: review.text.original,
			reviewRating: review.reviewRating,
			commentDateRaw: review.commentDate?.raw,
			commentDate: review.commentDate?.original,
			avatar: review.avatar,
			userMeta: {gender: review.gender, age: review.age, race: review.race}
		});
	}

	return (
		<Modal opened={props.isOpen} onClose={onClose} title={tt('title')} size={'70rem'}>
			<Grid>
				<Grid.Col span={6}>
					<Textarea
						disabled={disabled}
						autosize
						minRows={4}
						label={tt('prompt.label')}
						description={tt('prompt.description')}
						value={prompt}
						onChange={e => setPrompt(e.currentTarget.value)}
					/>
					<NumberInput
						value={amount}
						onChange={e => setAmount(Number(e))}
						allowDecimal={false}
						min={1}
						max={20}
						label={tt('amount.label')}
					/>
					<Button fullWidth disabled={disabled} onClick={runGenerate}>{tt('submitButton')}</Button>
				</Grid.Col>
				<Grid.Col span={6}>
					<Flex justify={'space-between'}>
						<Text>{tt('reviews.title')}</Text>
						<Checkbox label={tt('reviews.showTranslated')} checked={showTranslated} onChange={e => setShowTranslated(e.currentTarget.checked)}/>
					</Flex>
					{comments.map((comment, index) => {
						function getText(key: string, newLine?: boolean) {
							if(showTranslated && !newLine) return `${comment[key]?.original} (${comment[key]?.translated})`;
							if(showTranslated && newLine) return `${comment[key]?.original}\n------\n${comment[key]?.translated}`;
							return comment[key].original;
						}
						return (
							<Fieldset legend={getText('name')}>
								<TextInput
									inputWrapperOrder={['label', 'input', 'description']}
									leftSection={<Avatar src={getFileLink(comment.avatar)}/>}
									value={getText('name')}
									readOnly
									label={tt('reviews.row.name')}
									description={`${comment.gender} ${comment.age} ${comment.race}`}
								/>
								<TextInput value={comment.rating} readOnly label={tt('reviews.row.rating')}/>
								<Textarea value={getText('text', true)} readOnly label={tt('reviews.row.text')} autosize/>
								<TextInput value={comment.reviewRating} readOnly label={tt('reviews.row.reviewRating')}/>
								<TextInput value={getText('commentDate')} readOnly label={tt('reviews.row.commentDate')}/>
								<SimpleGrid cols={2}>
									<Button disabled={comment.added} fullWidth onClick={() => addToReviews(index)}>
										{tt('reviews.row.addToReviews')}
									</Button>
									<Button fullWidth onClick={() => removeReview(index)} color={'red'}>
										{tt('reviews.row.delete')}
									</Button>
								</SimpleGrid>
							</Fieldset>
						);
					})}
				</Grid.Col>
			</Grid>
		</Modal>
	);
}
