import {useEffect, useState} from "react";
import {useParams} from "wouter";
import {usePromise} from "@hooks/usePromise.ts";
import {YoContextEditor} from "@components/YoContextEditor";
import {InputField} from "@components/YoContextEditor/components/inputField.tsx";
import {Button} from "@mantine/core";
import {notifications} from "@mantine/notifications";

import {CfAccountsApiClient} from "./api.ts";
import {Header} from "@components/Header";
import {ConfirmDeleteModal} from "@modals/confirmModal.tsx";
import {useNavigate} from "@hooks/useNavigate.tsx";
import {SelectField} from "@components/YoContextEditor/components/selectField.tsx";
import {CollapseField, eqString} from "@components/YoContextEditor/components/CollapseField.tsx";
import {Translation, useLocale} from "../../locale";
import {displayErrorNotification} from "@serviceComponents/displayErrorNotification.tsx";

const api = new CfAccountsApiClient();
export function ViewCfAccounts() {
	const t = useLocale();
	const navigate = useNavigate();
	const params = useParams<{id: string}>();
	const promise = usePromise(() => api.fetchItem(params.id), [params.id]);
	const [data, setData] = useState({});
	const [changes, setChanges] = useState({});
	useEffect(() => setData(promise.data?.item ?? {}), [promise.isLoading]);

	function trySave() {
		api.saveItem(params.id, changes).then(() => {
			notifications.show({message: t('cfAccounts.edit.saveSuccess')});
			setChanges({});
		}).catch(displayErrorNotification);
	}

	async function tryRemove() {
		if(!await ConfirmDeleteModal()) return false;
		return api.deleteItem(params.id).then(() => navigate('/'))
	}

	async function tryDeployConsumer() {
		api.deployConsumer(params.id).then(() => {
			notifications.show({message: 'consumer was queued for deployment'});
		}).catch(displayErrorNotification);
	}

	async function tryRedeployApps() {
		api.redeployApps(params.id).then((result) => {
			notifications.show({message: `${result.appsCount} apps was queued for deployment`});
		}).catch(displayErrorNotification);
	}

	return (
		<YoContextEditor item={data} setItem={setData} changes={changes} setChanges={setChanges}>
			<Header
				title={t('cfAccounts.edit.title')}
				items={(
					<>
						<Button onClick={trySave} size={'compact-sm'}>
							<Translation id={'cfAccounts.edit.saveButton'}/>
						</Button>
						<Button size={'compact-sm'} color={'red'} onClick={tryRemove}>
							<Translation id={'cfAccounts.edit.removeButton'}/>
						</Button>
					</>
				)}
			/>
			<InputField
				field={'name'}
				label={t('cfAccounts.edit.form.name')}
			/>
			<SelectField
				field={'keyType'}
				label={t('cfAccounts.edit.form.keyType')}
				data={[{
					label: t('cfAccounts.edit.form.keyType.token'),
					value: 'TOKEN'
				}, {
					label: t('cfAccounts.edit.form.keyType.key'),
					value: 'KEY'
				}]}
			/>
			<CollapseField field={'keyType'} eqFn={eqString('TOKEN')}>
				<InputField field={'apiToken'} label={t('cfAccounts.edit.form.apiToken')} />
			</CollapseField>
			<CollapseField field={'keyType'} eqFn={eqString('KEY')}>
				<InputField field={'apiKey'} label={t('cfAccounts.edit.form.apiKey')} />
				<InputField field={'apiEmail'} label={t('cfAccounts.edit.form.apiEmail')} />
			</CollapseField>
			<InputField field={'accountId'} label={t('cfAccounts.edit.form.accountId')} />
			<hr/>
			<div>
				<Button onClick={tryDeployConsumer}>
					Deploy consumer
				</Button>
			</div>
			<hr/>
			<div>
				<Button onClick={tryRedeployApps}>
					Redeploy apps
				</Button>
			</div>
		</YoContextEditor>
	);
}
