import {MRT_ColumnDef} from "mantine-react-table";
import {Anchor, Badge} from "@mantine/core";
import {Link} from "wouter";

export const viewOwnerColl: MRT_ColumnDef<any> = {
	accessorKey: 'owner',
	header: 'Owner',
	enableColumnFilter: false,
	Cell: props => {
		const value = props.cell.getValue<{_id: string, username: string} | string>();
		if(!value) return <Badge color={'red'}>Owner not found</Badge>;
		if(typeof value === 'string') return <Badge color={'yellow'}>{value}</Badge>;
		return <Anchor component={Link} href={`~/system/users/v/${value._id}`}>{value.username}</Anchor>

	}
};
