import {IconAppWindow} from "@tabler/icons-react";
import MenuManager from "@engine/MenuManager.ts";
import {Text, Card, Stack, Grid, Box, Group, Alert} from "@mantine/core";

MenuManager.RegisterItem({
	position: -1,
	path: '/',
	key: 'main-page',
	nameKey: 'menuItems.mainPage',
	requiredRights: [],
	icon: <IconAppWindow/>
})

type StatisticsSectionData = {
	label: string,
	currentValue: number,
	previousValue: number,
	percent?: boolean
}

const mockData: StatisticsSectionData[] = [
	{
		label: 'Визиты',
		currentValue: 12823,
		previousValue: 10000
	},
	{
		label: 'Уникальных визитов',
		currentValue: 12823,
		previousValue: 10000
	},
	{
		label: 'Регистраций',
		currentValue: 12823,
		previousValue: 10000
	},
	{
		label: 'Депозитов',
		currentValue: 12823,
		previousValue: 10000
	},
	{
		label: 'Визиты к уникам',
		currentValue: 80,
		previousValue: 4,
		percent: true
	},
	{
		label: 'Уники к регистрациям',
		currentValue: 20,
		previousValue: 6,
		percent: true
	},
	{
		label: 'Регистрации к депозитам',
		currentValue: 80,
		previousValue: 4,
		percent: true
	},
	{
		label: 'Регистрации к депозитам',
		currentValue: 80,
		previousValue: 4,
		percent: true
	},
];

function StatisticsSection ({
	label,
	currentValue,
	previousValue,
	percent
}: StatisticsSectionData) {
	return (
		<Card withBorder h={280}>
			<Stack justify="space-between" h="100%">
				<Text fw={600} c="white" size="20px">
					{label}
				</Text>
				<Box>
					{
						percent ? (
							<Group gap={0}>
								<Text c="brand.6" size="48px" fw={900}>
									{`+${((previousValue / currentValue) * 100).toFixed()}`}
								</Text>
								<Text size="48px" fw={900}>
									{`%`}
								</Text>
							</Group>
						) : (
							<Group gap="xs" align="end">
								<Text c="brand.6" size="48px" fw={900}>
									{currentValue.toLocaleString()}
								</Text>
								<Group mb={8} gap={0}>
									<Text c="brand.6" size="24px" fw={900}>
										{`+${((previousValue / currentValue) * 100).toFixed()}`}
									</Text>
									<Text size="24px" fw={900}>
										{`%`}
									</Text>
								</Group>
							</Group>
						)
					}
					<Text size="14px" fw={400}>
						По сравнению с предыдущим месяцем
					</Text>
				</Box>
			</Stack>
		</Card>
	)
}

export function MainPage() {
	return (
		<div>
			<Alert>
				MOCKED DATA
			</Alert>
			<Grid>
				{
					mockData.map((item, index) => (
						<Grid.Col span={3} key={index}><StatisticsSection {...item}/></Grid.Col>
					))
				}
			</Grid>
		</div>
	)
}
