import {useYoProvider, YoContextEditor} from "@components/YoContextEditor/index.tsx";
import {ReactNode} from "react";
import {arrayMove} from "@dnd-kit/sortable";
import {SimpleSorting} from "@components/SimpleSorting/simpleSorting.tsx";
import {SimpleSortItemProvider} from "@components/SimpleSorting/simpleSortItemProvider.tsx";


export function YoContextArrayMap<T extends {_id: string}>(props: {field: string, children: ReactNode, withSort?: boolean}) {
	const ctx = useYoProvider<Array<T>>(props.field, {defaultValue: []});

	function onRemove(index: number) {
		const arr = ctx.value.slice();
		arr.splice(index,1);
		return ctx.onChange(arr);
	}

	if(props.withSort) {
		return (
			<SimpleSorting
				onDragEnd={(res) => ctx.onChange(arrayMove(ctx.value.slice(), res.oldIndex,res.newIndex))}
				arr={ctx.value as {_id: string}[]}
			>
				{ctx.value.map((_el, index) => {
					return (
						<YoContextEditor name={props.field} index={index} key={index} bypassProps={{
							onRemove: () => onRemove(index),
							__index: index,
						}}>
							<SimpleSortItemProvider sortId={_el._id}>
								{props.children}
							</SimpleSortItemProvider>
						</YoContextEditor>
					)
				})}
			</SimpleSorting>
		)
	}

	return (
		<>
			{ctx.value.map((_el, index) => {
				return (
					<YoContextEditor name={props.field} index={index} key={index} bypassProps={{
						onRemove: () => onRemove(index),
						__index: index,
					}}>
						{props.children}
					</YoContextEditor>
				)
			})}
		</>
	);
}

