import MenuManager from "@engine/MenuManager.ts";
import {IconWorldWww} from "@tabler/icons-react";
import {GoDaddySettingsPage} from "./goDaddySettingsPage.tsx";
import {Tabs} from "@mantine/core";
import {NameCheapSettingsPage} from "./nameCheapSettingsPage.tsx";
import {PorkBunSettingsPage} from "./porkBunSettingsPage.tsx";
import {AdmToolsSettingsPage} from "./admToolsSettingsPage.tsx";
import {CloudflareSettingsPage} from "./cloudflareSettingsPage.tsx";

MenuManager.RegisterItem({
    path: '/system/settings/domainRegistrars',
    parent: 'system',
    key: 'domainRegistrarsSettings',
    name: 'Настройки регистраторов',
    nameKey: 'menuItems.system.domainRegistrarsSettings',
    icon: <IconWorldWww/>
});

const tabs = [
    {
        key: 'goDaddy',
        label: 'GoDaddy',
        Component: GoDaddySettingsPage
    },
    {
        key: 'nameCheap',
        label: 'NameCheap',
        Component: NameCheapSettingsPage
    },
    {
        key: 'porkBun',
        label: 'PorkBun',
        Component: PorkBunSettingsPage
    },
    {
        key: 'admTools',
        label: 'AdmTools',
        Component: AdmToolsSettingsPage
    },
    {
        key: 'cloudflare',
        label: 'Cloudflare',
        Component: CloudflareSettingsPage
    }
]

function DomainRegistrarSettings() {
    return (
        <Tabs defaultValue={tabs[0]?.key}>
            <Tabs.List>
                {
                    tabs.map(tab => (
                        <Tabs.Tab key={tab.key} value={tab.key}>
                            {tab.label}
                        </Tabs.Tab>
                    ))
                }
            </Tabs.List>
                {
                    tabs.map(({ key, Component }) => (
                        <Tabs.Panel key={key} value={key}>
                            <Component />
                        </Tabs.Panel>
                    ))
                }
        </Tabs>
    );
}

export default DomainRegistrarSettings;