import {useScopedLocale} from "../../../../locale";
import {Alert, Button, Card, Center, Group, Loader, Modal, Stack, Text} from "@mantine/core";
import {IconInfoCircle} from "@tabler/icons-react";
import {useDisclosure} from "@mantine/hooks";
import {DomainsApiClient} from "../../../Domains/api.ts";
import {usePromise} from "@hooks/usePromise.ts";
import {Link, useParams} from "wouter";
import {useState} from "react";
import {displayErrorNotification} from "@serviceComponents/displayErrorNotification.tsx";
import {useYoProvider} from "@components/YoContextEditor";
import useTeams from "../../../Teams/Context/useTeams.ts";

const api = new DomainsApiClient();

function DomainCard({domain, onClick}: {domain: any, onClick: (domain: any) => void}) {
	return (
		<Card key={domain.domain}>
			<Group wrap="nowrap" justify="space-between">
				<div>
					<Text>
						{domain.domain}
					</Text>
					{domain.webApp && (
						<Link href={`~/webApps/v/${domain.webApp._id}`}><Text size="xs" color="dimmed">{domain.webApp.name}</Text></Link>
					)}
					{domain.state !== 'OK' && (
						<Text size="xs" color="dimmed">{domain.state}</Text>
					)}
				</div>
				<Group gap={0} wrap="nowrap">
					<Button disabled={!!domain.webApp} onClick={() => onClick(domain)}>
						{domain.webApp ? `Not available` : 'Select'}
					</Button>
				</Group>
			</Group>
		</Card>
	)
}

function SelectExistsDomain({onChange}: {onChange: (domain: any) => void}) {
	const params = useParams<{id: string}>()
	const { activeTeam } = useTeams();
	const [isLoading, setLoading] = useState(false);
	const promise = usePromise(() => {
		return api.fetchList({limit: 1000, query: { team: activeTeam }}).then(res => res.items.sort((a) => a.webApp ? 1 : -1));
	}, [activeTeam as string]);

	function tryLinkDomain(domain: any) {
		setLoading(true);

		return api.linkExistingDomain(domain._id, {pwaId: params.id}).then(() => {
			onChange(domain);
		}).catch(displayErrorNotification).finally(() => {
			setLoading(false);
		});
	}

	const loading = isLoading || promise.isLoading;

	return (
		<Stack>
			{loading && <Center><Loader/></Center>}
			{promise.isError && <Center><Text>{promise.errData?.message}</Text></Center>}
			{!loading && !promise.isError && promise.data!.map(el => <DomainCard key={el._id} domain={el} onClick={() => tryLinkDomain(el)}/>)}
		</Stack>
	);
}

export function LinkExistsDomain() {
	const tt = useScopedLocale('webApps.view.DomainTab.');
	const domainCtr = useYoProvider('domain');
	const [isOpen, {toggle}] = useDisclosure(false);

	return (
		<>
			<Alert
				mb="lg"
				icon={<IconInfoCircle/>}
				variant="info"
				title={<div>{tt('linkExists.title')}</div>}
			>
				<div>{tt('linkExists.guide.1')}</div>
				<br/>
				<Button fullWidth variant="secondary" onClick={toggle}>{tt('linkExists.openModal')}</Button>
			</Alert>
			<Modal opened={isOpen} onClose={toggle} title={tt('linkExists.modalTitle')} size={'lg'} keepMounted={false}>
				<SelectExistsDomain onChange={domain => {
					domainCtr.onChange(domain._id);
					toggle();
				}}/>
			</Modal>
		</>
	);
}
