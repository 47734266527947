import {Badge, Button} from "@mantine/core";
import {Link} from "wouter";
import {useYoApi} from "@hooks/useYoApi.tsx";
import {JobsApiClient} from "./api.ts";
import {Header} from "@components/Header";
import {useNavigate} from "@hooks/useNavigate.tsx";
import {useMemo} from "react";
import {MantineReactTable, MRT_ColumnDef, useMantineReactTable} from "mantine-react-table";
import {useMRTYoApiDataAdapter} from "@hooks/useMRTYoApiDataAdapter.tsx";
import {useMRTState} from "@hooks/useMRTState.tsx";
import {useDefaultsTableState} from "@hooks/useDefaultsTableState.tsx";
import {yoFormModal} from "@modals/yoFormModal.tsx";
import {Translation, useLocale} from "../../locale";

const api = new JobsApiClient()

interface IItem {
	_id: string;
	subStatus: string;
	errReason: string;
}

export function ListJobs() {
	const t = useLocale();
	const navigate = useNavigate();
	const defaults = useDefaultsTableState();

	const createTableState = useMRTState<IItem>({
		visibilityLocalStorageKey: 'ui.Jobs.columnVisibility',
		defaults
	});

	const columns = useMemo<MRT_ColumnDef<IItem>[]>(() => [{
		accessorKey: '_id',
		header: t('jobs.list.tableHeader._id'),
		enableSorting: false,
		enableColumnActions: false,
	},{
		accessorKey: 'type',
		header: t('jobs.list.tableHeader.type'),
	},{
		accessorKey: 'state',
		header: t('jobs.list.tableHeader.state'),
		filterVariant: 'select',
		mantineFilterSelectProps: {
			data: ['WAIT', 'IN_PROGRESS', 'DONE', 'ERROR']
		},
		Cell: props => {
			const state = props.cell.getValue<string>();
			if (state === 'WAIT') return <Badge color={'red'}><Translation id={'jobs.list.tableHeader.state.WAIT'}/></Badge>;
			if (state === 'IN_PROGRESS') return <Badge color={'blue'}><Translation id={'jobs.list.tableHeader.state.IN_PROGRESS'}/>{props.row.original.subStatus}</Badge>;
			if (state === 'ERROR') return <Badge color={'red'} title={props.row.original.errReason}><Translation id={'jobs.list.tableHeader.state.ERROR'}/></Badge>;
			if (state === 'DONE') return <Badge color={'green'}><Translation id={'jobs.list.tableHeader.state.DONE'}/></Badge>;
			return <Badge color={'gray'}><Translation id={'jobs.list.tableHeader.state.UNKNOWN'}/></Badge>;
		}
	}, {
		accessorKey: 'createdAt',
		header: t('jobs.list.tableHeader.createdAt'),
	}, {
		accessorKey: 'actions',
		header: t('jobs.list.tableHeader.actions'),
		enableSorting: false,
		enableColumnActions: false,
		Cell: (props) => (
			<>
				<Link href={`/v/${props.row.original._id}`}>
					<a>
						<Button size={'compact-sm'}>
							<Translation id={'jobs.list.tableHeader.actions.edit'}/>
						</Button>
					</a>

				</Link>

			</>
		)
	}], []);

	const data = useYoApi({apiClient: api, defaults});
	const tableState = createTableState({
		data,
		columns
	});
	useMRTYoApiDataAdapter({
		apiClient: data,
		tableParams: tableState.adapterProps,
		defaults,
	});
	const table = useMantineReactTable<IItem>(tableState);

	async function tryCreate() {
		const data = await yoFormModal<any>({
			title: t('jobs.list.createJobTitle'),
			required: [],
			defaultValue: {comments: []},
			size: 'lg',
			body: (
				<>

				</>
			)
		})
		return api.createItem(data).then(res => navigate(`/v/${res.item._id}`))
	}

	return <>
		<Header
			title={'Jobs'}
			items={(
				<>
					<Button size={'compact-sm'} onClick={tryCreate}>
						<Translation id={'jobs.list.createJobButton'}/>
					</Button>
				</>
			)}
		/>
		<MantineReactTable table={table}/>
	</>;
}
