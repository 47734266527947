import {useEffect, useState} from "react";
import {useParams} from "wouter";
import {usePromise} from "@hooks/usePromise.ts";
import {YoContextEditor} from "@components/YoContextEditor";
import {InputField} from "@components/YoContextEditor/components/inputField.tsx";
import {Button, Card, Stack, Title} from "@mantine/core";
import {notifications} from "@mantine/notifications";

import {PushSegmentsApiClient} from "./api.ts";
import {Header} from "@components/Header";
import {ConfirmDeleteModal} from "@modals/confirmModal.tsx";
import {useNavigate} from "@hooks/useNavigate.tsx";
import {displayErrorNotification} from "@serviceComponents/displayErrorNotification.tsx";
import {Translation, useLocale} from "../../../locale";
import {RenderFuncField} from "@components/YoContextEditor/components/renderFuncField.tsx";
import {MultiSelectWebApp} from "../../WebApps/selectWebApp.tsx";
import {CheckboxField} from "@components/YoContextEditor/components/checkboxField.tsx";
import TeamSelectField from "@components/YoContextEditor/teamSelect.tsx";

const api = new PushSegmentsApiClient();
export function ViewPushSegments() {
	const navigate = useNavigate();
	const t = useLocale();
	const params = useParams<{id: string}>();
	const promise = usePromise(() => api.fetchItem(params.id), [params.id]);
	const [data, setData] = useState({});
	const [changes, setChanges] = useState({});
	useEffect(() => setData(promise.data?.item ?? {}), [promise.isLoading]);

	function trySave() {
		api.saveItem(params.id, changes).then(() => {
			notifications.show({message: t('push.segments.edit.saveSuccess')});
			setChanges({});
		}).catch(displayErrorNotification);
	}

	async function tryRemove() {
		if(!await ConfirmDeleteModal()) return false;
		return api.deleteItem(params.id).then(() => navigate('/'))
	}

	return (
		<YoContextEditor item={data} setItem={setData} changes={changes} setChanges={setChanges}>
			<Header
				backButtonUrl=""
				title={t('push.segments.edit.title')}
				items={(
					<>
						<Button variant="primary" onClick={trySave} size={'compact-sm'}>
							<Translation id={'push.segments.edit.actions.save'}/>
						</Button>
						<Button size={'compact-sm'} color={'red'} onClick={tryRemove}>
							<Translation id={'push.segments.edit.actions.remove'}/>
						</Button>
					</>
				)}
			/>
			<Card withBorder component={Stack}>
				<TeamSelectField />
				<InputField
					field={'comment'}
					label={t('push.segments.edit.fields.comment')}
					description={t('push.segments.edit.fields.comment.description')}
				/>
				<RenderFuncField<string[]>
					field={'webApps'}
				>
					{ctx => (
						<MultiSelectWebApp
							label={t('push.segments.edit.fields.webApps')}
							hideAll
							onChange={ctx.onChange}
							value={ctx.value}
						/>
					)}
				</RenderFuncField>
				<Title order={4}>
					<Translation id={'push.segments.edit.flags'} />
				</Title>
				<CheckboxField field={'isTryAppInstall'} label={t('push.segments.edit.flags.isTryAppInstall')}/>
				<CheckboxField field={'isAppInstalled'} label={t('push.segments.edit.flags.isAppInstalled')}/>
				<CheckboxField field={'isAppRunned'} label={t('push.segments.edit.flags.isAppRunned')}/>
				<CheckboxField field={'isRegistered'} label={t('push.segments.edit.flags.isRegistered')}/>
				<CheckboxField field={'isDeposited'} label={t('push.segments.edit.flags.isDeposited')}/>
			</Card>
		</YoContextEditor>
	);
}
