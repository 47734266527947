import {useState} from "react";
import {useYoProvider} from "@components/YoContextEditor";
import ky from "ky";
import {displayErrorNotification} from "@serviceComponents/displayErrorNotification.tsx";
import {Menu} from "@mantine/core";
import {IconLanguageHiragana} from "@tabler/icons-react";

export function MoveFromBaseMenuItem(props: { field: string }) {
	const [disabled, setDisabled] = useState(false);
	const ctx = useYoProvider(props.field);
	const {lang, baseLang, isDefault} = ctx.bypassProps;
	const baseCtx = useYoProvider(`localeAppData.${baseLang}.${props.field}`, {upProvider: 1});
	const {value: translateEngine} = useYoProvider('translateEngine');

	function runTranslate() {
		setDisabled(true);
		return ky.post('/api/v1/utils/translate', {
			json: {
				text: baseCtx.value,
				language: lang,
				engine: translateEngine
			}
		}).json<{ status: boolean, text: string }>()
			.then(res => {
				ctx.onChange(res.text);
			}).catch(displayErrorNotification).finally(() => setDisabled(false));
	}

	function runCopy() {
		ctx.onChange(baseCtx.value);
	}

	if(isDefault || !baseCtx.value) return null;

	return (
		<>
			<Menu.Item disabled={disabled} onClick={runCopy} leftSection={<IconLanguageHiragana/>}>
				Скопировать значение с {baseLang} языка
			</Menu.Item>
			<Menu.Item disabled={disabled} onClick={runTranslate} leftSection={<IconLanguageHiragana/>}>
				Скопировать значение с {baseLang} и перевести
			</Menu.Item>
		</>

	);
}
