import {Route} from "wouter";
import {ListAdminUsers} from "./listAdminUsers.tsx";
import {ViewAdminUsers} from "./viewAdminUsers.tsx";
import MenuManager from "@engine/MenuManager.ts";
import {IconUsers} from "@tabler/icons-react";

const baseUrl = '/system/users';

MenuManager.RegisterItem({
	path: baseUrl,
	parent: 'system',
	key: 'AdminUsers',
	name: 'Пользователи панели',
	nameKey: 'menuItems.system.adminUsers',
	icon: <IconUsers/>
})

export function AdminUsersRouter(props: { basePath?: string }) {
	return (
		<Route nest path={props.basePath || baseUrl}>
			<Route path={'/'} component={ListAdminUsers}/>
			<Route path={'/v/:id'} component={ViewAdminUsers}/>
		</Route>
	);
}
