import {useEffect, useState} from "react";
import {useParams} from "wouter";
import {usePromise} from "@hooks/usePromise.ts";
import {YoContextEditor} from "@components/YoContextEditor";
import {InputField} from "@components/YoContextEditor/components/inputField.tsx";
import {Alert, Button, Card, SimpleGrid, Stack} from "@mantine/core";
import {notifications} from "@mantine/notifications";
import parseDuration from "parse-duration";

import {PushJobsApiClient} from "./api.ts";
import {Header} from "@components/Header";
import {ConfirmDeleteModal} from "@modals/confirmModal.tsx";
import {useNavigate} from "@hooks/useNavigate.tsx";
import {getLocaleString, Translation, useLocale} from "../../../locale";
import {displayErrorNotification} from "@serviceComponents/displayErrorNotification.tsx";
import {CheckboxField} from "@components/YoContextEditor/components/checkboxField.tsx";
import {RenderFuncField} from "@components/YoContextEditor/components/renderFuncField.tsx";
import {MultiSelectPushMessage} from "../PushMessages/SelectPushMessages.tsx";
import {MultiSelectPushSegments} from "../PushSegments/SelectPushSegments.tsx";
import {SelectField} from "@components/YoContextEditor/components/selectField.tsx";
import {CollapseField, eqString} from "@components/YoContextEditor/components/CollapseField.tsx";
import {DateTimePicker} from "@mantine/dates";
import {convertToSeconds} from "@shared/utils/timeStringToSeconds.ts";
import {yoFormModal} from "@modals/yoFormModal.tsx";
import {mongoObjectId} from "../../../utils/mongoObjectId.ts";
import {YoContextArrayMap} from "@components/YoContextEditor/YoContextArrayMap.tsx";
import {RulesHeaderField} from "../../WebApps/view/components/rules/RulesHeaderField.tsx";
import {TriggerTypeSelect} from "./triggerTypeSelect.tsx";
import {PeriodPopoverDoc} from "./periodPopoverDoc.tsx";
import TeamSelectField from "@components/YoContextEditor/teamSelect.tsx";
import {PushStats} from "../PushLogs/pushStats.tsx";

const api = new PushJobsApiClient();

function showAddTriggerModal(cb: (data: any) => void) {
	return yoFormModal({
		title: getLocaleString('push.jobs.edit.addTrigger.title'),
		body: (
			<>
				<RenderFuncField<string> field={'triggerType'}>
					{ctx => (
						<TriggerTypeSelect
							label={getLocaleString('push.jobs.edit.addTrigger.triggerType')}
							value={ctx.value}
							onChange={ctx.onChange}
						/>
					)}
				</RenderFuncField>
				<InputField
					field={'for'}
					label={getLocaleString('push.jobs.edit.addTrigger.for')}
					validateFn={(v) => {
						if(!v) return false;
						convertToSeconds(v);
						return true;
					}}
					rightSection={(
						<PeriodPopoverDoc field={'for'}/>
					)}
				/>
			</>
		)
	}).then(res => cb({...res,_id: mongoObjectId()}));

}

export function ViewPushJobs() {
	const navigate = useNavigate();
	const t = useLocale();
	const params = useParams<{ id: string }>();
	const promise = usePromise(() => api.fetchItem(params.id), [params.id]);
	const [data, setData] = useState({});
	const [changes, setChanges] = useState({});
	useEffect(() => setData(promise.data?.item ?? {}), [promise.isLoading]);

	function trySave() {
		api.saveItem(params.id, changes).then(() => {
			notifications.show({message: t('push.jobs.edit.saveSuccess')});
			setChanges({});
		}).catch(displayErrorNotification);
	}

	async function tryRemove() {
		if (!await ConfirmDeleteModal()) return false;
		return api.deleteItem(params.id).then(() => navigate('/'))
	}

	return (
		<YoContextEditor item={data} setItem={setData} changes={changes} setChanges={setChanges}>
			<Header
				backButtonUrl=""
				title={t('push.jobs.edit.title')}
				items={(
					<>
						<Button onClick={trySave} size={'compact-sm'} variant="primary">
							<Translation id={'push.jobs.edit.actions.save'}/>
						</Button>
						<Button size={'compact-sm'} color={'red'} onClick={tryRemove}>
							<Translation id={'push.jobs.edit.actions.remove'}/>
						</Button>
					</>
				)}
			/>
			<Card withBorder component={Stack}>
				<RenderFuncField<boolean> field={'isDefault'}>
					{ctx => ctx.value ? (
						<Alert color={'yellow'} title={t('push.jobs.edit.isDefault')}>
							{t('push.jobs.edit.isDefault.description')}
							<hr/>
							<Button onClick={() => ctx.onChange(false)} size={'compact-sm'}>
								{t('push.jobs.edit.isDefault.unlock')}
							</Button>
						</Alert>
					): null}
				</RenderFuncField>
				<TeamSelectField />
				<InputField
					field={'comment'}
					label={t('push.jobs.edit.fields.comment')}
					description={t('push.jobs.edit.fields.comment.description')}
				/>
				<CheckboxField
					field={'enabled'}
					label={t('push.jobs.edit.fields.enabled')}
					description={t('push.jobs.edit.fields.enabled.description')}
				/>
				<RenderFuncField<string[]> field={'messages'} >
					{ctx => (
						<MultiSelectPushMessage
							label={t('push.jobs.edit.fields.messages')}
							description={t('push.jobs.edit.fields.messages.description')}
							value={ctx.value}
							onChange={ctx.onChange as any}
						/>
					)}
				</RenderFuncField>
				<RenderFuncField<string[]> field={'segments'} >
					{ctx => (
						<MultiSelectPushSegments
							label={t('push.jobs.edit.fields.segments')}
							description={t('push.jobs.edit.fields.segments.description')}
							value={ctx.value}
							onChange={ctx.onChange as any}
						/>
					)}
				</RenderFuncField>
				<SelectField
					field={'runMode'}
					label={t('push.jobs.edit.fields.runMode')}
					description={t('push.jobs.edit.fields.runMode.description')}
					data={[{
						label: t('push.jobs.edit.fields.runMode.manual'),
						value: 'manual'
					}, {
						label: t('push.jobs.edit.fields.runMode.schedule'),
						value: 'schedule'
					}, {
						label: t('push.jobs.edit.fields.runMode.trigger'),
						value: 'trigger'
					}]}
				/>
				<CollapseField field={'runMode'} eqFn={eqString('schedule')}>
					<RenderFuncField<string | Date>  field={'scheduleProps.nextRun'}>
						{ctx => (
							<DateTimePicker
								label={t('push.jobs.edit.fields.scheduleProps.nextRun')}
								minDate={new Date()}
								value={ctx.value ? new Date(ctx.value) : undefined}
								onChange={ctx.onChange}
							/>
						)}
					</RenderFuncField>
					<br/>
					<CheckboxField field={'scheduleProps.repeat'} label={t('push.jobs.edit.fields.scheduleProps.repeat')}/>
					<br/>
					<CollapseField field={'scheduleProps.repeat'}>
						<InputField
							validateFn={(v) => {
								if(!v) return false;
								convertToSeconds(v);
								return true;
							}}
							field={'scheduleProps.repeatPeriod'}
							label={t('push.jobs.edit.fields.scheduleProps.repeatPeriod')}
							rightSection={(
								<PeriodPopoverDoc field={'scheduleProps.repeatPeriod'}/>
							)}
						/>
					</CollapseField>
				</CollapseField>
				<CollapseField field={'runMode'} eqFn={eqString('trigger')}>
					<h4>Тригеры</h4>
					<YoContextArrayMap field={'triggerProps.triggers'}>
						<RulesHeaderField getBadgeContent={(ctx) => `Trigger #${ctx.bypassProps.__index + 1}`}/>
						<SimpleGrid cols={2}>
							<RenderFuncField<string> field={'triggerType'}>
								{ctx => (
									<TriggerTypeSelect
										label={getLocaleString('push.jobs.edit.addTrigger.triggerType')}
										value={ctx.value}
										onChange={ctx.onChange}
									/>
								)}
							</RenderFuncField>
							<InputField
								field={'delay'}
								label={getLocaleString('push.jobs.edit.addTrigger.delay')}
								validateFn={(v) => {
									if(!v) return false;
									return !!parseDuration(v);

								}}
								rightSection={(
									<PeriodPopoverDoc field={'delay'}/>
								)}
							/>
						</SimpleGrid>
						<hr/>
					</YoContextArrayMap>.
					<RenderFuncField field={'triggerProps.triggers'}>
						{ctx => (
							<Button onClick={() => showAddTriggerModal(data => ctx.push(data))}>
								<Translation id={'push.jobs.edit.fields.triggerProps.triggers.add'}/>
							</Button>
						)}
					</RenderFuncField>
				</CollapseField>
			</Card>

			<PushStats selector={{jobId: params.id}}/>
		</YoContextEditor>
	);
}
