import {Alert, Card, Collapse, List, Stack, Title} from "@mantine/core";
import {CheckboxField} from "@components/YoContextEditor/components/checkboxField.tsx";
import {CollapseField} from "@components/YoContextEditor/components/CollapseField.tsx";
import {InputField} from "@components/YoContextEditor/components/inputField.tsx";
import {RenderFuncField} from "@components/YoContextEditor/components/renderFuncField.tsx";
import {useScopedLocale} from "../../../locale";
import {IconInfoCircle} from "@tabler/icons-react";

export function PushTab() {
	const tt = useScopedLocale('webApps.view.PushTab.');
	const ct = useScopedLocale('webApps.view.');

	return (
		<Card withBorder>
			<Title order={4} mb="lg">
				{ct('tabs.list.push')}
			</Title>
			<Title order={5}>{tt('title')}</Title>
			<Card variant="section" mb="xl">
				<CheckboxField field={'selfPush.enabled'} label={tt('selfPush.enabled')}/>
				<CollapseField field={'selfPush.enabled'}>
					<Stack pt="xl">
					<RenderFuncField<{promptOnStart: boolean, promptOnInstall: boolean}> field={'selfPush'}>
						{ctx => (
							<Collapse in={!ctx.value?.promptOnStart && !ctx.value?.promptOnInstall}>
								<Alert color={'red'} title={'Ошибка конфигурации'}>
									{tt('selfPush.alert')}
								</Alert>
								<br/>
							</Collapse>
						)}
					</RenderFuncField>
					<CheckboxField
						field={'selfPush.promptOnInstall'}
						label={tt('selfPush.promptOnInstall')}
					/>
					<CheckboxField
						field={'selfPush.promptOnStart'}
						label={tt('selfPush.promptOnStart.label')}
						description={tt('selfPush.promptOnStart.description')}
					/>
					<CheckboxField
						field={'selfPush.useDefaultPush'}
						label={tt('selfPush.useDefaultPush.label')}
						description={tt('selfPush.useDefaultPush.description')}
					/>
					</Stack>
				</CollapseField>
			</Card>
			<Title order={4}>{tt('oneSignal.title')}</Title>
			<Alert icon={<IconInfoCircle />} variant="info" title={`${tt('oneSignal.description1')} ${tt('oneSignal.description2')}`}>
				<CheckboxField field={'oneSignal.enabled'} label={tt('oneSignal.enabled')}/>
				<CollapseField field={'oneSignal.enabled'} pt="md">
					<InputField field={'oneSignal.appId'} label={tt('oneSignal.appId.label')}/>
					<br/>
					<Alert variant="gradient" title={tt('oneSignal.alert.replace.title')}>
						<List spacing="sm" mt="sm">
							<List.Item>
								{tt('oneSignal.alert.t1')} <strong>Advanced Push Settings</strong> {tt('oneSignal.alert.t2')}{' '}
								<strong>"Customize service worker paths and filenames"</strong>.
							</List.Item>
							<List.Item>
								{tt('oneSignal.alert.replace.title')}
								<List withPadding mt="xs">
									<List.Item>
										<strong>Path to service worker files:</strong>{' '}
										<code>/_oneSignal/</code>
									</List.Item>
									<List.Item>
										<strong>Main service worker filename:</strong>{' '}
										<code>OneSignalSDKWorker.js</code>
									</List.Item>
									<List.Item>
										<strong>Updater service worker filename:</strong>{' '}
										<code>OneSignalSDKWorker.js</code> (Именно таким)
									</List.Item>
									<List.Item>
										<strong>Service worker registration scope:</strong>{' '}
										<code>/_oneSignal</code>
									</List.Item>
								</List>
							</List.Item>
						</List>
					</Alert>
				</CollapseField>
			</Alert>
		</Card>
	);
}
