import {IconChevronRight, IconLogout, IconSearch} from '@tabler/icons-react';
import {Avatar, Group, Menu, rem, Text, UnstyledButton} from "@mantine/core";
import classes from './style.module.pcss'
import {useUser} from "@engine/UserContext.tsx";
import defaultUserIcon from './user.png';
import {spotlight} from "@mantine/spotlight";
import useTeams from "../../Pages/Teams/Context/useTeams.ts";
import {teamUserRoles} from "@shared/_data/teamUserRoles.ts";

export function UserMenu() {
	const {user, logout} = useUser();
	const { myTeams, activeTeam } = useTeams();
	const selectedTeam = myTeams.find(e => e._id === activeTeam);
	const role = teamUserRoles[selectedTeam?.role || 'admin'].label;

	const displayBalance = selectedTeam ? selectedTeam.balance : 0;

	return (
		<Menu width={200} shadow="md">
			<Menu.Target>
				<UnstyledButton className={classes.user}>
					<Group>
						<Avatar
							src={defaultUserIcon}
							radius="xl"
						/>
						<div style={{flex: 1}}>
							<Text size="sm" fw={500} c="brand">
								{user.username}
							</Text>
							<Text c="dimmed" size="xs">
								{user.isSuperAdmin ? 'SuperAdmin*' : role}
							</Text>
							<Text c="dimmed" size="xs">
								{`Balance: ${displayBalance.toLocaleString('en-US', {
									style: 'currency',
									currency: 'USD'
								})}`}
							</Text>
						</div>
						<IconChevronRight style={{width: rem(14), height: rem(14)}} stroke={1.5}/>
					</Group>
				</UnstyledButton>
			</Menu.Target>
			<Menu.Dropdown>
				<Menu.Item
					leftSection={<IconSearch style={{ width: rem(14), height: rem(14) }} />}
					onClick={() => spotlight.open()}
				>
					Spotlight
				</Menu.Item>
				<Menu.Item
					leftSection={<IconLogout style={{ width: rem(14), height: rem(14), color: 'red' }} />}
					onClick={logout}
				>
					Выйти
				</Menu.Item>
			</Menu.Dropdown>
		</Menu>
	)

}
