import {Route} from "wouter";
import {ListFbPixels} from "./listFbPixels.tsx";
import {ViewFbPixels} from "./viewFbPixels.tsx";
import MenuManager from "@engine/MenuManager.ts";
import {IconBrandFacebook} from "@tabler/icons-react";
import {FbPixelsLogsRouter} from "./FbPixelsLogs";

const baseUrl = '/fbPixels';

MenuManager.RegisterItem({
	path: baseUrl,
	key: 'FbPixels',
	name: 'Мои пиксели',
	nameKey: 'menuItems.fbPixels',
	icon: <IconBrandFacebook />,
	requiredRights: ['pixels']
})

export function FbPixelsRouter(props: { basePath?: string }) {
	return (
		<Route path={props.basePath || baseUrl} nest>
			<Route path={'/'} component={ListFbPixels}/>
			<Route path={'/v/:id'} component={ViewFbPixels}/>
			<FbPixelsLogsRouter basePath={'/logs/:pixelId'}/>
		</Route>
	);
}
