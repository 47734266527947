import {Alert, Button, Modal, TextInput} from "@mantine/core";
import {IconInfoCircle} from "@tabler/icons-react";
import {useScopedLocale} from "../../../../locale";
import {DomainsApiClient} from "../../../Domains/api.ts";
import {useMemo, useState} from "react";
import {useDisclosure} from "@mantine/hooks";
import {displayErrorNotification} from "@serviceComponents/displayErrorNotification.tsx";
import {CopyButtonWithTooltip} from "../../../../utils/copyButtonWithTooltip.tsx";
import {useParams} from "wouter";
import {useYoProvider} from "@components/YoContextEditor";

const domainsApi = new DomainsApiClient();

function LinkDomanWizard({onDone}: {onDone: (domain: any) => void}) {
	const params = useParams<{id: string}>();
	const tt = useScopedLocale('webApps.view.DomainTab.link.');
	const [step, setStep] = useState(0);
	const [domain, setDomain] = useState('');
	const [isLoading, setLoading] = useState(false);
	const [domainMetadata, setDomainMetadata] = useState<{_id: string, nsRecords: string[]}>();
	const isValidDomain = useMemo(() => {
		const domainRegex = /^(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,63}$/;
		return domainRegex.test(domain);
	}, [domain]);

	async function requestNS() {
		setLoading(true);
		return domainsApi.linkDomain(domain, {pwaId: params.id}).then(res => {
			setDomainMetadata(res.item);
			setStep(1);
		}).catch(displayErrorNotification).finally(() => {
			setLoading(false);
		});
	}

	function onLinkDone() {
		onDone(domainMetadata);
		setStep(0);
		setDomain('');
	}

	if(step === 0) {
		return (
			<>
				<TextInput disabled={isLoading} label={tt('wizard.1.domain.label')} value={domain} onChange={e => setDomain(e.target.value)}/>
				<hr/>
				<Button disabled={!isValidDomain || isLoading} onClick={requestNS}>
					{tt('wizard.1.continue')}
				</Button>
			</>
		)
	} else if(step === 1 && domainMetadata) {
		return (
			<>
				<div>{tt('wizard.2.nsListTitle')}</div>
				{domainMetadata.nsRecords.map((record, index) => (
					<TextInput
						key={index}
						label={tt('wizard.2.nsListItem').replace('{{i}}', String(index+1))}
						value={record}
						readOnly
						rightSection={(
							<CopyButtonWithTooltip value={record}/>
						)}
					/>
				))}
				<hr/>
				<Button onClick={onLinkDone}>
					{tt('wizard.2.done')}
				</Button>
			</>
		)
	} else {
		return '???'
	}
}

export function LinkDomain() {
	const [isOpen, {toggle}] = useDisclosure(false);
	const tt = useScopedLocale('webApps.view.DomainTab.link.');
	const domainCtr = useYoProvider('domain');

	return (
		<>
			<Alert
				mb="lg"
				icon={<IconInfoCircle/>}
				variant="info"
				title={<div>{tt('guide.1')}</div>}
			>
				<div>{tt('guide.2')}</div>
				<br/>
				<Button fullWidth variant="secondary" onClick={toggle}>{tt('openModal')}</Button>
			</Alert>
			<Modal opened={isOpen} onClose={toggle} title={tt('modalTitle')} size={'lg'}>
				<LinkDomanWizard onDone={(domain) => {
					domainCtr.onChange(domain._id);
					toggle();
				}}/>
			</Modal>
		</>
	);
}
