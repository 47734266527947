import {createContext, createElement, ReactNode, useContext} from "react";
import {usePromise} from "@hooks/usePromise.ts";
import ky from "ky";
import {match, P} from "ts-pattern";
import {Alert, LoadingOverlay} from "@mantine/core";
import {displayTSMatchGeneralError} from "@serviceComponents/displayTSMatchGeneralError.tsx";
import {AuthPage} from "../Pages/AuthPage";

interface PubUser {
	_id: string;
	username: string;
	isSuperAdmin: boolean,
}

const Context = createContext<{user: PubUser, settings: any, logout: () => void}>({} as any);

export function useUser() {
	return useContext(Context);
}


function userStore({data, setData, children}: {data: any, setData: any, children: any}) {
	function logout() {
		setData({status: true, auth: false})
		return ky.delete('/api/v1/auth').catch(() => {});
	}
	return (
		<Context.Provider
			value={{
				user: data.user,
				settings: data.settings,
				logout
			}}
		>
			{children}
		</Context.Provider>
	)
}

export function UserContext({children}: {children: ReactNode}) {
	const p = usePromise(() => {
		const sp = new URLSearchParams(window.location.search);
		if(sp.has('tgWebAppOneTimeAuthToken')) {
			return ky.post('/api/v1/auth/tgLogin', {json: {tgWebAppOneTimeAuthToken: sp.get('tgWebAppOneTimeAuthToken')}}).json<{ status: boolean, auth: boolean, user: any }>();
		}

		return ky.get('/api/v1/auth', {}).json<{ status: boolean, auth: boolean, user: any, settings: any }>();
	}, [])
	return match(p)
		.with({isLoading: true}, () => <LoadingOverlay visible/>)
		.with({isLoading:false, data: {auth: true, user: P.any}}, (data) => createElement(userStore, data as any, children))
		.with({isLoading: false, data: {auth: false}}, ({setData}) => <AuthPage onAuth={setData}/>)
		.with({isError: true}, displayTSMatchGeneralError)
		.otherwise((data) => <Alert color={'red'}><pre>{JSON.stringify(data,null,4)}</pre></Alert>)
}
