import {YoApiClient} from "@engine/yoApiClient.tsx";
import {singleton} from "@ood/singleton";
import type { RawDocumentTypeTeams} from "@shared/models";
import type { TeamUserRoleKeys, TeamUserRoleRights } from "@shared/_data/teamUserRoles.ts";

type ApiResult <T> = ({
	status: true,
} & T) | ({
	status: false,
	message: string
});

export type TMyTeam = { _id: string; name: string, rights: TeamUserRoleRights[], role: TeamUserRoleKeys, balance: number };
export type TTeamUser = { _id: string, username: string; role: string };

@singleton
export class TeamsApiClient extends YoApiClient<RawDocumentTypeTeams> {
	path = '/api/v1/teams';

	async inviteUser (teamId: string, email: string, role: TeamUserRoleKeys) {
		this.ky.post(`${teamId}/invite`, { json: { email, role } });
	}

	async createNewUser (teamId: string, email: string, role: TeamUserRoleKeys) {
		return this.ky.post(`${teamId}/createNewUser`, { json: { email, role } }).json<{ username: string, password: string }>();
	}

	async getMyTeams() {
		return this.ky.get('getMyTeams').json<ApiResult<{ items: TMyTeam[] }>>();
	}

	async getTeamsUsers(id: string) {
		return this.ky.get(`${id}/getTeamsUsers`).json<ApiResult<{ items: TTeamUser[] }>>()
	}

	async updateUserRole(teamId: string, teamUserId: string, role: string) {
		return this.ky.post(`${teamId}/updateUserRole`, { json: { role, teamUserId } });
	}

	async deleteTeamUser(teamId: string, teamUserId: string) {
		return this.ky.post(`${teamId}/deleteTeamUser`, { json: { teamUserId } });
	}

	async proceedMigration() {
		return this.ky.post('proceedMigration', { timeout: false });
	}
}
