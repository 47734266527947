import {YoContextEditor} from "@components/YoContextEditor";
import {InputField} from "@components/YoContextEditor/components/inputField.tsx";
import {Header} from "@components/Header";
import {Button} from "@mantine/core";
import {notifications} from "@mantine/notifications";
import {useSiteSettings} from "@hooks/UseSiteSettings.tsx";
import {Translation, useLocale} from "../../../../locale";
import {displayErrorNotification} from "@serviceComponents/displayErrorNotification.tsx";

/*MenuManager.RegisterItem({
	path: '/system/settings/porkBun',
	parent: 'system',
	key: 'porkBunSettings',
	name: 'Настройки регистратора porkBun',
	nameKey: 'menuItems.system.porkBunSettings',
	icon: <IconWorldWww/>
})*/

export function PorkBunSettingsPage() {
	const controller = useSiteSettings('domains.porkBun.');
	const t = useLocale();

	function saveSettings() {
		return controller.saveChanges()
			.then(() => notifications.show({message: t('settings.general.savedMessage')}))
			.catch(displayErrorNotification)
	}

	return (
		<YoContextEditor {...controller.controller}>
			<Header title={t('settings.porkBun.title')} items={(
				<>
					<Button onClick={saveSettings}>
						<Translation id={'settings.general.saveBth'}/>
					</Button>
				</>
			)}/>
			<InputField field={'apiKey'} label={t('settings.porkBun.apiKey')}/>
			<InputField field={'secret'} label={t('settings.porkBun.secret')}/>
			<InputField field={'currency'} label="currency"/>
		</YoContextEditor>
	);
}
