import {Card, Group, Text} from '@mantine/core';
import classes from './style.module.css';
import {IconArrowDownRight, IconArrowUpRight} from "@tabler/icons-react";


export function StatsGrid(props: { cardStyle?: any; description?: string; title: string, value: string | number, diff?: number, icon?: any, onClick?: () => void}) {
	const Icon = props.icon;
	const DiffIcon = typeof props.diff === 'number' ? props.diff > 0 ? IconArrowUpRight : IconArrowDownRight : IconArrowUpRight;
	return (
		<Card variant="section" withBorder onClick={props.onClick} style={props.cardStyle}>
			<Group justify="space-between">
				<Text size="xs" c="dimmed" className={classes.title}>
					{props.title}
				</Text>
				{Icon && <Icon className={classes.icon} size="1.4rem" stroke={1.5}/>}
			</Group>
			<Group align="flex-end" gap="xs" mt={15} justify={'space-between'}>
				<Text className={classes.value}>{props.value}</Text>
				{props.diff ? (
					<Text c={props.diff > 0 ? 'teal' : 'red'} fz="sm" fw={500} className={classes.diff}>
						<span>{props.diff}%</span>
						<DiffIcon size="1rem" stroke={1.5}/>
					</Text>
				): null}
			</Group>

			<Text fz="xs" c="dimmed" mt={7}>
				{props.description}
			</Text>
		</Card>
	);
}
