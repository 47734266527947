import {iStatsSelector, PushLogsApiClient, statsTypes} from "./api.ts";
import {usePromise} from "@hooks/usePromise.ts";
import {Card, Flex, Loader, SimpleGrid, Title} from "@mantine/core";
import {displayTSMatchGeneralError} from "@serviceComponents/displayTSMatchGeneralError.tsx";
import {StatsGrid} from "@components/StatsGrid";
import {TimeRangeSelect, useTimeState} from "@components/timeRangeSelect.tsx";
import {endOfToday, startOfToday} from "date-fns";
import {useState} from "react";
import {AreaChart} from "@mantine/charts";

const api = new PushLogsApiClient();


function StatChart(props: { from: Date, to: Date, chartType: statsTypes, selector: iStatsSelector }) {
	const promise = usePromise(() => api.fetchStatsChart(props.chartType, {
		from: props.from,
		to: props.to,
	}, props.selector), [props.from.getTime(), props.to.getTime(), props.chartType, JSON.stringify(props.selector)]);

	if (promise.isLoading) return <Loader/>;
	if (promise.isError) return displayTSMatchGeneralError(promise);
	return (
		<AreaChart
			h={300}
			data={promise.data!.chart}
			dataKey="l"
			series={promise.data!.series}
			curveType="linear"
		/>
	);
}

function View(props: { from: Date, to: Date, selector: iStatsSelector }) {
	const [chartType, setChartType] = useState<statsTypes>('opened');
	const promise = usePromise(() => api.fetchStats({
		from: props.from,
		to: props.to,
	}, props.selector), [props.from.getTime(), props.to.getTime(), JSON.stringify(props.selector)]);

	if (promise.isLoading) return <Loader/>;
	if (promise.isError) return displayTSMatchGeneralError(promise);
	const {sent, opened, delivered, failed} = promise.data!;
	return (
		<>
			<Flex>
				<SimpleGrid cols={4}>
					<StatsGrid
						value={sent.value}
						title={'Отправлено'}
						diff={sent.diff}
						onClick={() => setChartType('sent')}
						cardStyle={{cursor: 'pointer', backgroundColor: chartType === 'sent' ? 'darkblue' : undefined}}
					/>
					<StatsGrid
						value={opened.value}
						title={'Открыто'}
						diff={opened.diff}
						onClick={() => setChartType('opened')}
						cardStyle={{cursor: 'pointer', backgroundColor: chartType === 'opened' ? 'darkblue' : undefined}}
					/>
					<StatsGrid
						value={delivered.value}
						title={'Доставлено'}
						diff={delivered.diff}
						onClick={() => setChartType('delivered')}
						cardStyle={{cursor: 'pointer', backgroundColor: chartType === 'delivered' ? 'darkblue' : undefined}}
					/>
					<StatsGrid
						value={failed.value}
						title={'Не доставлено'}
						diff={failed.diff}
						onClick={() => setChartType('failed')}
						cardStyle={{cursor: 'pointer', backgroundColor: chartType === 'failed' ? 'darkblue' : undefined}}
					/>

				</SimpleGrid>
			</Flex>
			<br/>
			<SimpleGrid cols={2}>
				<div>
					<h4>Chart</h4>
					<StatChart
						from={props.from}
						to={props.to}
						chartType={chartType}
						selector={props.selector}
					/>
				</div>
				<div/>
			</SimpleGrid>
			<br/>
		</>
	);
}

export function PushStats(props: { selector: iStatsSelector }) {
	const timeControls = useTimeState(startOfToday(), endOfToday());

	return (
		<>
			<Flex justify={'space-between'}>
				<Title order={4}>Статистика</Title>
				<TimeRangeSelect onChange={timeControls.setValue} defaultMode={'today'}/>
			</Flex>

			<Card variant="section">
				<View from={timeControls.value[0]!} to={timeControls.value[1]!} selector={props.selector}/>
			</Card>
		</>
	)


}
