import {Button} from "@mantine/core";
import {Link} from "wouter";
import {useYoApi} from "@hooks/useYoApi.tsx";
import {CfAccountsApiClient} from "./api.ts";
import {Header} from "@components/Header";
import {useNavigate} from "@hooks/useNavigate.tsx";
import {useMemo} from "react";
import {
	MantineReactTable,
	useMantineReactTable,
	MRT_ColumnDef
} from "mantine-react-table";
import {useMRTYoApiDataAdapter} from "@hooks/useMRTYoApiDataAdapter.tsx";
import {useMRTState} from "@hooks/useMRTState.tsx";
import {useDefaultsTableState} from "@hooks/useDefaultsTableState.tsx";
import {Translation, useLocale} from "../../locale";
import {yoFormModal} from "@modals/yoFormModal.tsx";
import {InputField} from "@components/YoContextEditor/components/inputField.tsx";
import {SelectField} from "@components/YoContextEditor/components/selectField.tsx";
import {CollapseField, eqString} from "@components/YoContextEditor/components/CollapseField.tsx";

const api = new CfAccountsApiClient()

interface IItem {
	_id: string;
}

export function ListCfAccounts() {
	const t = useLocale();
	const navigate = useNavigate();
	const defaults = useDefaultsTableState();

	const createTableState = useMRTState<IItem>({
		visibilityLocalStorageKey: 'ui.cfAccounts.columnVisibility',
		defaults
	});

	const columns = useMemo<MRT_ColumnDef<IItem>[]>(() => [{
		accessorKey: '_id',
		header: t('cfAccounts.list.tableHeader._id'),
		enableSorting: false,
		enableColumnActions: false,
	},{
		accessorKey: 'name',
		header: t('cfAccounts.list.tableHeader.name'),
	},{
		accessorKey: 'keyType',
		header: t('cfAccounts.list.tableHeader.keyType'),
		Cell: props => `${t(`cfAccounts.list.tableHeader.keyType.${props.cell.getValue<string>()}` as any)} (${props.cell.getValue()})`
	},{
		accessorKey: 'actions',
		header: t('cfAccounts.list.tableHeader.actions'),
		enableSorting: false,
		enableColumnActions: false,
		Cell: (props) => (
			<>
				<Link href={`/v/${props.row.original._id}`} >
					<a>
						<Button size={'compact-sm'}>
							<Translation id={'cfAccounts.list.tableHeader.actions.view'}/>
						</Button>
					</a>

				</Link>

			</>
		)
	}], []);

	const data = useYoApi({ apiClient: api, defaults });
	const tableState = createTableState({
		data,
		columns
	});
	useMRTYoApiDataAdapter({
		apiClient: data,
		tableParams: tableState.adapterProps,
		defaults,
	});
	const table = useMantineReactTable<IItem>(tableState);

	async function tryCreate() {
		const formData = await yoFormModal<any>({
			title: t('cfAccounts.modal.title.create'),
			defaultValue: {keyType: 'TOKEN'},
			validateFn: obj => obj.name && obj.keyType === 'TOKEN' ? obj.apiToken : obj.keyType === 'KEY' ? obj.apiKey && obj.apiEmail : false,
			body: (
				<>
					<InputField
						field={'name'}
						label={t('cfAccounts.modal.label.name')}
					/>
					<SelectField
						field={'keyType'}
						label={t('cfAccounts.modal.label.keyType')}
						data={[{
							label: t('cfAccounts.modal.label.keyType.token'),
							value: 'TOKEN'
						}, {
							label: t('cfAccounts.modal.label.keyType.key'),
							value: 'KEY'
						}]}
					/>
					<CollapseField field={'keyType'} eqFn={eqString('TOKEN')}>
						<InputField field={'apiToken'} label={t('cfAccounts.modal.label.apiToken')} />
					</CollapseField>
					<CollapseField field={'keyType'} eqFn={eqString('KEY')}>
						<InputField field={'apiKey'} label={t('cfAccounts.modal.label.apiKey')} />
						<InputField field={'apiEmail'} label={t('cfAccounts.modal.label.apiEmail')} />
					</CollapseField>
					<InputField
						field={'accountId'}
						label={t('cfAccounts.modal.label.accountId')}
						description={(
							<a target={'_blank'} rel={'noreferrer noopener'} href="https://developers.cloudflare.com/fundamentals/setup/find-account-and-zone-ids/">
								<Translation id={'cfAccounts.modal.label.accountId.description'}/>
							</a>
						)}
					/>
				</>
			)
		});
		return api.createItem(formData).then(res => navigate(`/v/${res.item._id}`))
	}

	return <>
		<Header
			title={t('cfAccounts.list.title')}
			items={(
				<>
					<Button size={'compact-sm'} onClick={tryCreate}>
						<Translation id={'cfAccounts.list.create'}/>
					</Button>
				</>
			)}
		/>
		<MantineReactTable table={table} />
	</>;
}
