import {Collapse, Container, Grid, Tabs} from "@mantine/core";
import {IconFlag, IconImageInPicture, IconLanguage, IconPrompt, IconTextCaption, IconUsers} from "@tabler/icons-react";
import {DescriptionTab} from "./descriptionTab.tsx";
import {ImagesTab} from "./imagesTab.tsx";
import {ReviewsTab} from "./reviewsTab.tsx";
import {useParams} from "wouter";
import {useLocalStorage} from "@mantine/hooks";
import {LanguagesTab} from "./languagesTab.tsx";
import {useYoProvider, YoContextEditor} from "@components/YoContextEditor";
import {Fragment, useMemo} from "react";
import {allLanguageList} from "@shared/_data/allLanguageList.ts";
import {useLocaleContext, useScopedLocale} from "../../../../locale";
import {ContentSettingsTab} from "./contentSettingsTab.tsx";
import {PreviewFrame} from "./previewFrame.tsx";

function LangName({code}: {code: string}) {
	const {selectedLocale} = useLocaleContext();
	return useMemo(() => {
		const val = allLanguageList.find(el => el.code === code);
		if(!val) return code;
		if(selectedLocale === 'ru') return val.name_ru;
		if(selectedLocale === 'uk') return val.name_ukr;
		return val.name_en;
	}, [code]);
}

function LangIcon({code}: {code: string}) {
	return useMemo(() => {
		const val = allLanguageList.find(el => el.code === code);
		if(!val) return <IconFlag />;
		return <div style={{ width: '24px', height: '24px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{val.emoji}</div>;
	}, [code]);
}


export function ContentTab() {
	const tt = useScopedLocale('webApps.views.ContentTab.');
	const params = useParams<{id: string}>();
	const [tab, setTab] = useLocalStorage<{tab: string, langCode?: string}>({key: `ui.webApps.${params.id}.tab.content.v2`, defaultValue: {
		tab: 'languages'
	}});
	const ctx = useYoProvider('localeAppData');
	const langs = Object.entries<{__deleted?: boolean, isDefault: boolean}>(ctx.value || {}).filter(el => !el[1]?.__deleted).map(el => ({
		code: el[0],
		isDefault: el[1].isDefault
	}));

	const baseLang = langs.find(el => el.isDefault);

	const currentLangCode = tab.langCode;

	return (
		<>
			<Tabs variant="pills" value={tab.tab} orientation={'vertical'} keepMounted={false}>
				<Tabs.List>
					<Tabs.Tab
						value='languages'
						leftSection={<IconLanguage />}
						onClick={() => setTab({tab: 'languages'})}
					>
						{tt('tabsList.languages')}
					</Tabs.Tab>
					{langs.map(({code}) => {
						function switchTab(name: string) {
							return setTab({tab: name!, langCode: code});
						}
						return (
							<Fragment key={code}>
								<Tabs.Tab
									value={code}
									leftSection={<LangIcon code={code}/>}
									onClick={() => switchTab('description')}
								>
									<LangName code={code}/>
								</Tabs.Tab>
								<Collapse in={tab.langCode === code}>
									<Tabs.Tab
										value={`description`}
										onClick={() => switchTab('description')}
										leftSection={<IconTextCaption/>}
										style={{width: '90%', marginLeft: '18px'}}
									>
											{tt('tabsList.description')}
									</Tabs.Tab>
									<Tabs.Tab
										value={`images`}
										leftSection={<IconImageInPicture/>}
										style={{width: '90%', marginLeft: '18px'}}
										onClick={() => switchTab('images')}
									>
										{tt('tabsList.images')}
									</Tabs.Tab>
									<Tabs.Tab
										value={`reviews`}
										leftSection={<IconUsers/>}
										style={{width: '90%', marginLeft: '18px'}}
										onClick={() => switchTab('reviews')}
									>
										{tt('tabsList.reviews')}
									</Tabs.Tab>
								</Collapse>
							</Fragment>
						);
					})}
					<Tabs.Tab
						value='settings'
						leftSection={<IconPrompt/>}
						onClick={() => setTab({tab: 'settings'})}
					>
						{tt('tabsList.additional')}
					</Tabs.Tab>
				</Tabs.List>
				<Grid w={'100%'}>
					<Grid.Col span={currentLangCode ? 9 : 12}>
						<Tabs.Panel value="languages">
							<Container fluid>
								<LanguagesTab/>
							</Container>
						</Tabs.Panel>
						<Tabs.Panel value="settings">
							<Container fluid>
								<ContentSettingsTab/>
							</Container>
						</Tabs.Panel>
						<YoContextEditor name={`localeAppData.${currentLangCode || 'LNF'}`} key={currentLangCode} bypassProps={{lang: currentLangCode, isSecondaryLanguage: !baseLang, baseLang: baseLang?.code}}>
							<Tabs.Panel value={`description`}>
								<Container fluid>
									<DescriptionTab/>
								</Container>
							</Tabs.Panel>
							<Tabs.Panel value={`images`}>
								<Container fluid>
									<ImagesTab/>
								</Container>
							</Tabs.Panel>
							<Tabs.Panel value={`reviews`} >
								<Container fluid>
									<ReviewsTab/>
								</Container>
							</Tabs.Panel>
						</YoContextEditor>
					</Grid.Col>
					{currentLangCode && (
						<Grid.Col span={3} h={'100%'}>
							<PreviewFrame locale={currentLangCode}/>
						</Grid.Col>
					)}
				</Grid>
			</Tabs>
		</>
	);
}
