import {useNavigate} from "@hooks/useNavigate.tsx";
import {Link, useParams} from "wouter";
import {usePromise} from "@hooks/usePromise.ts";
import {useEffect, useState} from "react";
import {TeamsApiClient} from "../api.ts";
import {notifications} from "@mantine/notifications";
import {displayErrorNotification} from "@serviceComponents/displayErrorNotification.tsx";
import {ConfirmDeleteModal} from "@modals/confirmModal.tsx";
import {Anchor, Button, Loader} from "@mantine/core";
import {YoContextEditor} from "@components/YoContextEditor";
import {Header} from "@components/Header";
import MembersList from "./MembersList.tsx";
import {type RawDocumentTypeTeams} from "@shared/models";
import {InputField} from "@components/YoContextEditor/components/inputField.tsx";
import {VisibleOnlyAdmin} from "../../../utils/visibleOnlyAdmin.tsx";
import {RawDocumentType} from "@shared/lib/ugly/rawDocumentType.ts";
import {AdminUser} from "@shared/models/AdminUsers.ts";

const api = new TeamsApiClient();



export function ViewTeams() {
    const navigate = useNavigate();
    const params = useParams<{ id: string }>();

    const promise = usePromise(() => api.fetchItem(params.id), [params.id]);
    const [data, setData] = useState<(RawDocumentTypeTeams & { owner: RawDocumentType<AdminUser> }) | null>(null);
    const [changes, setChanges] = useState({});
    useEffect(() => promise.data && setData(promise.data.item as any), [promise.isLoading]);

    function trySave() {
        api.saveItem(params.id, changes).then(() => {
            notifications.show({ message: "Saved" });
            setChanges({});
        }).catch(displayErrorNotification)
    }

    async function tryRemove() {
        if(!await ConfirmDeleteModal()) return false;
        return api.deleteItem(params.id).then(() => navigate('/'))
    }

    if (!data) return <Loader />

    return (
        <YoContextEditor item={data} setItem={setData} changes={changes} setChanges={setChanges} bypassProps={{hasUnsavedChanges: Object.keys(changes).length}}>
            <Header
                backButtonUrl=""
                title={`Team «${data.name || 'unnamed'}»`}
                items={(
                    <>
                        <Button variant="primary" size="compact-sm" onClick={trySave}>Save</Button>
                        <Button variant="outline" size="compact-sm"  color="white" onClick={tryRemove}>Delete</Button>
                    </>
                )}
            />
            <VisibleOnlyAdmin>
                <Anchor mb="sm" fw={600} component={Link} to={`~/system/users/v/${data.owner._id}`}>
                    {`Owner: ${data.owner?.username}`}
                </Anchor>
            </VisibleOnlyAdmin>
            <InputField
                label="name"
                field="name"
                mb="xl"
            />
            <MembersList teamId={data._id} />
        </YoContextEditor>
    )
}