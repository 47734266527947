import {ImageUploaderField} from "@components/YoContextEditor/components/imageUploaderField.tsx";
import {Card, Center, Grid, Image, Title} from "@mantine/core";
import {YoContextArrayMap} from "@components/YoContextEditor/YoContextArrayMap.tsx";
import {RenderFuncField} from "@components/YoContextEditor/components/renderFuncField.tsx";
import {SimpleDragHandle} from "@components/SimpleSorting/simpleDragHandle.tsx";
import {IconTrash} from "@tabler/icons-react";
import {getFileLink} from "../../../../utils/getFileLink.tsx";
import {ImageUploader} from "@YoUi/imageUploader.tsx";
import {mongoObjectId} from "../../../../utils/mongoObjectId.ts";
import {CheckboxField} from "@components/YoContextEditor/components/checkboxField.tsx";
import {CollapseField} from "@components/YoContextEditor/components/CollapseField.tsx";
import {useIsSecondary} from "../hooks/useIsSecondary.tsx";
import {useScopedLocale} from "../../../../locale";


export function ImagesTab() {
	const tt = useScopedLocale('webApps.view.ImagesTab.');
	const ct = useScopedLocale('webApps.views.ContentTab.');
	const isSecondary = useIsSecondary();

	return (
		<Card withBorder>
			<Title order={4} mb="lg">
				{ct('tabsList.images')}
			</Title>
			<Title order={5} >
				{tt('icon.title')}
			</Title>
			<Card variant="section" mb="lg">
				{isSecondary && (
					<CheckboxField field={'content.useIconFromBaseLanguage'} label={tt('useIconFromBaseLanguage')}/>
				)}
				<CollapseField field={'content.useIconFromBaseLanguage'} eqFn={val => isSecondary ? !val: true}>
					<ImageUploaderField field={'content.icon'}/>
				</CollapseField>
			</Card>
			<Title order={5}>{tt('useScreensFromBaseLanguage.title')}</Title>
			<Card variant="section">
				{isSecondary && (
					<CheckboxField field={'content.useScreensFromBaseLanguage'} label={tt('useScreensFromBaseLanguage.label')}/>
				)}
				<CollapseField field={'content.useScreensFromBaseLanguage'} eqFn={val => isSecondary ? !val: true}>
					<br/>
					<Grid>
						<YoContextArrayMap field={'content.screens'} withSort>
							<Grid.Col span={'content'}>
								<RenderFuncField<string> field={'image'}>
									{ctx => (
										<>
											<div style={{display: 'flex', justifyContent: 'space-between'}}>
												<SimpleDragHandle/>
												<IconTrash onClick={ctx.bypassProps.onRemove}/>
											</div>
											<Center maw={400} h={300} bg="var(--mantine-color-gray-light)">
												<Image
													src={getFileLink(ctx.value)}
													radius="md"
													h={300}
													w={300}
													fit="contain"
												/>
											</Center>
										</>
									)}
								</RenderFuncField>
							</Grid.Col>
						</YoContextArrayMap>
						<Grid.Col span={12}>
							<RenderFuncField field={'content.screens'}>
								{ctx => <ImageUploader
									onDropAll={files => {
										ctx.push(...files.map(file => ({_id: mongoObjectId(), image: file._id})));
									}}/>}
							</RenderFuncField>
						</Grid.Col>
					</Grid>
				</CollapseField>
			</Card>
		</Card>
	);
}
