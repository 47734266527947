import {Button, Container, Paper, PasswordInput, TextInput, Title} from "@mantine/core";
import classes from './style.module.css';
import {useState} from "react";
import ky from "ky";
import {notifications} from "@mantine/notifications";
import {useSetField} from "@hooks/useSetField.ts";
import {Translation, useLocale} from "../../locale";
import {displayErrorNotification} from "@serviceComponents/displayErrorNotification.tsx";

export function AuthPage({onAuth}: { onAuth: any }) {
	const t = useLocale();
	const [form, setField] = useSetField<any>({});
	const [disabled, setDisabled] = useState(false);

	function tryLogin() {
		setDisabled(true);
		ky.post(`/api/v1/auth`, {json: form}).json<{ status: boolean, user?: any, settings?: any }>().then(res => {
			if (!res.status) {
				notifications.show({
					color: 'yellow',
					title: t('auth.authError.title'),
					message: t('auth.authError.message'),
				});
			} else {
				onAuth({auth: true, user: res.user, settings: res.settings})
			}
		}).catch(displayErrorNotification).finally(() => setDisabled(false))
	}

	return (
		<Container size={420} my={40}>
			<Title ta="center" className={classes.title}>
				<Translation id={'auth.title'}/>
			</Title>

			<Paper withBorder shadow="md" p={30} mt={30} radius="md">
				<TextInput
					disabled={disabled}
					value={form.username}
					onChange={e => setField('username', e.currentTarget.value)}
					label="Email"
					placeholder="you@7k.wtf"
					required
				/>
				<PasswordInput
					disabled={disabled}
					value={form.password}
					onChange={e => setField('password', e.currentTarget.value)} label="Password"
					placeholder="Your password" required mt="md"/>
				<Button fullWidth mt="xl" disabled={disabled} onClick={tryLogin}>
					<Translation id={'auth.login'}/>
				</Button>
			</Paper>
		</Container>
	);
}
