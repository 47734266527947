import {FormEvent, useEffect, useState} from "react";
import {DomainsApiClient} from "./api.ts";
import {
    ActionIcon,
    Box,
    Button,
    Card,
    Group,
    Loader,
    Menu,
    Skeleton,
    Stack,
    Text,
    TextInput,
    ThemeIcon
} from "@mantine/core";
import {IconChevronLeft, IconDots, IconSearch, IconX} from "@tabler/icons-react";
import {notifications} from "@mantine/notifications";
import {displayErrorNotification} from "@serviceComponents/displayErrorNotification.tsx";
import {RegistrarKeys} from "@shared/lib/RegistrarsApi/registrars.ts";

const api = new DomainsApiClient();

export interface DomainSuggestMetadata {
    registrar: string,
    domain: string
}

function DisplayBalance ({ registrar }: { registrar: RegistrarKeys }) {
    const [balance, setBalance] = useState<number | null>(null);

    useEffect(() => {
        setBalance(null);
        const loadRegistrarBalance = async () => {
            try {
                await api.checkRegistrarBalance(registrar).then(res => {
                    if (!res.status) throw res.message;
                    setBalance(res.result);
                })
            } catch(e)  {
                // void displayErrorNotification(e);
                setBalance(-1);
            }
        }
        void loadRegistrarBalance();
    }, [registrar]);

    console.log(balance)

    if (balance === null) {
        return (
            <Loader size="xs" />
        )
    }

    if (balance === -1) {
        return "-"
    }

    return (
        balance?.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD'
        })
    )
}

function SuggestDomains({ value, onChange, disabled }: { disabled?: boolean, onChange: (v?: DomainSuggestMetadata) => void, value: DomainSuggestMetadata | undefined }) {
    const [list, setList] = useState<{ domain: string, displayPrice?: string, displayRegistrar?: string, lowestValue?: number, prices?: { [RegistrarKey: string]: { value: number, formatedString: string } } }[]>([]);
    const [loading, setLoading] = useState(false);
    const [query, setQuery] = useState('');


    const [domainData, setDomainData] = useState<{ displayPrice: string, domain: string } | null>(null)

    const handleConfirm = (e: FormEvent) => {
        e.preventDefault();
        if (query.includes('.')) {
            setList([{ domain: query }]);
            setLoading(true);
            api.getDomainsPrices([query])
                .then(data => {
                    if (!data.status) throw data.message;
                    setList(_prev => {
                        return _prev.map(item => {
                            const _item = Object.entries(data.result).find(([key]) => item.domain === key)?.[1];

                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            const lowestPrice = _item ? Object.entries(_item).sort(([_, a], [__, b]) => a.value - b.value)?.[0] : undefined;

                            return ({
                                domain: item.domain,
                                prices: _item || {},
                                displayRegistrar: lowestPrice?.[0],
                                lowestValue: lowestPrice?.[1]?.value || Infinity,
                                displayPrice: _item ? lowestPrice?.[1]?.formatedString : undefined,
                            })
                        })
                    })
                })
                .catch((err: any) => {
                    setLoading(false);
                    notifications.show({message: err.message || err, title: 'Error', color: 'red'})
                })
                .finally(() => setLoading(false))
        } else {
            setList([]);
            setLoading(true);
            api.suggestDomains(query).then(data => {
                if (!data.status) throw data.message;
                if (data.status) {
                    setList(() => {
                        return data.result.map(domain => ({domain}))
                    });
                    api.getDomainsPrices(data.result).then(data => {
                        if (!data.status) throw data.message;
                        setList(_prev => {
                            return _prev.map(item => {
                                const _item = Object.entries(data.result).find(([key]) => item.domain === key)?.[1];

                                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                const lowestPrice = _item ? Object.entries(_item).sort(([_, a], [__, b]) => a.value - b.value)?.[0] : undefined;

                                return ({
                                    domain: item.domain,
                                    prices: _item || {},
                                    displayRegistrar: lowestPrice?.[0],
                                    lowestValue: lowestPrice?.[1]?.value || Infinity,
                                    displayPrice: _item ? lowestPrice?.[1]?.formatedString : undefined,
                                })
                            })
                        })
                    }).finally(() => {
                        setLoading(false)
                    });
                }
            }).catch((err: any) => {
                setLoading(false);
                notifications.show({message: err.message || err, title: 'Error', color: 'red'})
            });
        }
    }

    useEffect(() => {
        setDomainData(null);
        if (value) {
            api.getDomainPrice({
                domain: value.domain,
                registrar: value.registrar
            }).then(data => {
                if (!data.status) throw data.message
                setDomainData(data.result);
            }).catch(e => {
                void displayErrorNotification(e);
                onChange(undefined);
            })
        }
    }, [value]);

    if (value) {
        if (!domainData) return <Loader />
        return (
            <Stack>
                <Group>
                    <ActionIcon
                        disabled={disabled}
                        onClick={() => onChange(undefined)}
                    >
                        <IconChevronLeft />
                    </ActionIcon>
                    <Text size="lg" fw={600}>
                        {value.domain}
                    </Text>
                </Group>
                <Group>
                    <Text>
                        Price:
                    </Text>
                    <Text c="green" size="lg">
                        {domainData.displayPrice}
                    </Text>
                </Group>
                <Text>
                    {`Registrar: ${value.registrar}`}
                </Text>
                <Group>
                    <Text>
                        Balance:
                    </Text>
                    <Text>
                        <DisplayBalance
                            registrar={value.registrar as RegistrarKeys}
                        />
                    </Text>
                </Group>
            </Stack>
        )
    }

    return (
        <Stack>
            <form onSubmit={handleConfirm}>
                <TextInput
                    placeholder="example.com"
                    value={query}
                    onChange={e => setQuery(e.target.value)}
                    rightSection={(<ActionIcon type="submit" disabled={loading}><IconSearch /></ActionIcon>)}
                />
            </form>
            {
                loading ? (
                    <Stack>
                        {new Array(10).fill(undefined).map((_, i) => (<Skeleton h={68} key={i} />))}
                    </Stack>
                ) : (
                    <Stack>
                        {
                            list.sort((a, b) => (a?.lowestValue || Infinity) - (b?.lowestValue || Infinity)).map(domain => (
                                <Card key={domain.domain}>
                                    <Group wrap="nowrap" justify="space-between">
                                        <Text>
                                            {domain.domain}
                                        </Text>
                                        {
                                            domain.displayPrice ? (
                                                <Group gap={0} wrap="nowrap">
                                                    <Button
                                                        onClick={() => {
                                                            onChange({
                                                                domain: domain.domain,
                                                                registrar: domain.displayRegistrar!
                                                            })
                                                        }}
                                                        style={{
                                                            borderTopRightRadius: 0,
                                                            borderBottomRightRadius: 0
                                                        }}
                                                    >
                                                        <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                                            <Text size="xs" lh="1rem" c="gray.4">
                                                                {domain.displayRegistrar}
                                                            </Text>
                                                            <Text lh="1rem" fw="600">{domain.displayPrice}</Text>
                                                        </Box>
                                                    </Button>
                                                    <Menu>
                                                        <Menu.Target>
                                                            <ActionIcon
                                                                size={36}
                                                                variant="filled"
                                                                style={{
                                                                    borderTopLeftRadius: 0,
                                                                    borderBottomLeftRadius: 0,
                                                                    border: 0,
                                                                    borderLeft: `1px solid var(--mantine-color-body)`
                                                                }}
                                                            >
                                                                <IconDots />
                                                            </ActionIcon>
                                                        </Menu.Target>
                                                        <Menu.Dropdown>
                                                            {
                                                                Object.entries(domain.prices || {}).map(([registrarKey, value]) => (
                                                                    <Menu.Item
                                                                        key={registrarKey}
                                                                        onClick={() => {
                                                                            onChange({
                                                                                domain: domain.domain,
                                                                                registrar: registrarKey
                                                                            })
                                                                        }}
                                                                    >
                                                                        <Group>
                                                                            <Text>
                                                                                {registrarKey}
                                                                            </Text>
                                                                            <Text>
                                                                                {value.formatedString}
                                                                            </Text>
                                                                        </Group>
                                                                    </Menu.Item>
                                                                ))
                                                            }
                                                        </Menu.Dropdown>
                                                    </Menu>
                                                </Group>
                                            ) : (
                                                    domain.prices ? (
                                                        <ThemeIcon color="gray" variant="transparent">
                                                            <IconX />
                                                        </ThemeIcon>
                                                    ) : (
                                                        <Loader size="xs"/>
                                                    )
                                            )
                                        }
                                    </Group>
                                </Card>
                            ))
                        }
                    </Stack>
                )
            }
        </Stack>
    );
}

export default SuggestDomains;
