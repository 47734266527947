import {useYoProvider} from "@components/YoContextEditor";
import {useState} from "react";
import {yoFormModal} from "@modals/yoFormModal.tsx";
import {ReviewForm} from "./reviewForm.tsx";
import {mongoObjectId} from "../../../../utils/mongoObjectId.ts";
import {ActionIcon, Button, Card, Grid, Group, SimpleGrid, Slider, Stack, Text, Title} from "@mantine/core";
import {NumberField} from "@components/YoContextEditor/components/numberField.tsx";
import {InputField} from "@components/YoContextEditor/components/inputField.tsx";
import {YoContextArrayMap} from "@components/YoContextEditor/YoContextArrayMap.tsx";
import {RenderFuncField} from "@components/YoContextEditor/components/renderFuncField.tsx";
import {SimpleDragHandle} from "@components/SimpleSorting/simpleDragHandle.tsx";
import {IconBrandOpenai, IconTrash} from "@tabler/icons-react";
import {GenerateReviewsModal} from "./generateReviewsModal.tsx";
import {useIsSecondary} from "../hooks/useIsSecondary.tsx";
import {CheckboxField} from "@components/YoContextEditor/components/checkboxField.tsx";
import {CollapseField} from "@components/YoContextEditor/components/CollapseField.tsx";
import {useScopedLocale} from "../../../../locale";

function RatingSlider({field, title}: { field: string, title: string }) {
	const ctx = useYoProvider<number>(field);
	return (
		<>
			<Text size="sm">{title}</Text>
			<Slider
				value={ctx.value ?? 50}
				onChange={el => ctx.onChange(el)}
			/>
		</>
	);
}


export function ReviewsTab() {
	const tt = useScopedLocale('webApps.view.ReviewsTab.');
	const ct = useScopedLocale('webApps.views.ContentTab.');
	const isSecondary = useIsSecondary();
	const reviewsCtx = useYoProvider('reviews');
	const [isOpen, setIsOpen] = useState(false);

	async function tryAddComment() {
		const data = await yoFormModal<any>({
			title: tt('tryAddComment.title'),
			required: ['name', 'text', 'rating', 'reviewRating'],
			defaultValue: {rating: 5},
			size: 'xxl',
			body: (
				<>
					<ReviewForm/>
				</>
			)
		});
		return reviewsCtx.push({_id: mongoObjectId(), ...data});
	}

	return (
		<Card withBorder>
			<Title order={4} mb="lg">
				{ct('tabsList.reviews')}
			</Title>
			<Title order={5} mb="xs">{tt('title')}</Title>
			<Card variant="section" mb="xl">
				{isSecondary && (
					<>
						<CheckboxField field={'content.useRatingFromBaseLanguage'} label={tt('useRatingFromBaseLanguage')}/>
						<br/>
					</>
				)}
				<CollapseField field={'content.useRatingFromBaseLanguage'} eqFn={val => isSecondary ? !val: true}>
					<Grid>
						<Grid.Col span={4}>
							<Text>
								{tt('rating.legend')}
							</Text>
							<Card variant="section" withBorder>
								<NumberField field={'content.rating'} label={tt('rating')}/>
								<InputField field={'content.ratingsCount'} label={tt('ratingsCount')}/>
								<InputField field={'content.downloadsCount'} label={tt('downloadsCount')}/>
							</Card>
						</Grid.Col>
						<Grid.Col span={8}>
							<Text>
								{tt('ratingValues.legend')}
							</Text>
							<Card variant="section" withBorder>
								<RatingSlider field={'content.ratingValues.five'} title={'5'}/>
								<RatingSlider field={'content.ratingValues.four'} title={'4'}/>
								<RatingSlider field={'content.ratingValues.three'} title={'3'}/>
								<RatingSlider field={'content.ratingValues.two'} title={'2'}/>
								<RatingSlider field={'content.ratingValues.one'} title={'1'}/>
							</Card>
						</Grid.Col>
					</Grid>
				</CollapseField>
			</Card>
			<YoContextArrayMap field={'reviews'}>
				<RenderFuncField<any>>
					{ctx => (
						<Stack gap="xs" mb="md">
							<Group justify="space-between">
								<Text>
									{ctx.value!.name} - {ctx.value!.rating}
								</Text>
								<Group align="center" gap={0}>
									<SimpleDragHandle/>
									<ActionIcon
										variant="subtle"
										c="white"
										onClick={ctx.bypassProps.onRemove}
									>
										<IconTrash size={'15px'} />
									</ActionIcon>
								</Group>
							</Group>
							<Card variant="section">
								<ReviewForm/>
							</Card>
						</Stack>
					)}
				</RenderFuncField>
			</YoContextArrayMap>
			<SimpleGrid cols={{md: 2, sm: 1}}>
				<Button variant="secondary" onClick={tryAddComment} fullWidth>{tt('reviews.button.add')}</Button>
				<Button variant="secondary" onClick={() => setIsOpen(true)} fullWidth><IconBrandOpenai/>{tt('reviews.button.generate')}</Button>
			</SimpleGrid>
			<GenerateReviewsModal isOpen={isOpen} closeModal={() => setIsOpen(false)}/>
		</Card>
	);
}
