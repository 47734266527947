import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/charts/styles.css';
import '@mantine/spotlight/styles.css';
import 'mantine-react-table/styles.css';
import {MantineProvider} from "@mantine/core";
import {Notifications} from "@mantine/notifications";
import {UserContext} from "./engine/UserContext.tsx";
import {Shell} from "./Shell";
import {Route} from "wouter";
import {MainPage} from "./Pages/MainPage.tsx";
import {ModalsProvider} from "@mantine/modals";
import {WebAppsRouter} from "./Pages/WebApps";
import {DomainsRouter} from "./Pages/Domains";
import {LeadsRouter} from "./Pages/Leads";
import {FbPixelsRouter} from "./Pages/FbPixels";
import {LocaleProvider} from "./locale";
import {AppSpotlight} from "@components/Spotlight/AppSpotlight.tsx";
import {GoogleTrackingEntryRouter} from "./Pages/GoogleTrackingEntry";
import {PushRouter} from "./Pages/Push";
import {WhitePagesRouter} from "./Pages/WhitePages";
import {AdminApp} from "./AdminApp.tsx";
import {TeamsRouter} from "./Pages/Teams";
import {TeamsContextProvider} from "./Pages/Teams/Context/TeamsContext.tsx";
import {appTheme} from "./theme.ts";
import {AvatarGalleryRouter} from "./Pages/AvatarGallery";

function App() {
	return (
		<Shell>
			<Route path={'/'} component={MainPage}/>
			<WebAppsRouter/>
			<DomainsRouter/>
			<LeadsRouter/>
			<FbPixelsRouter/>
			<GoogleTrackingEntryRouter/>
			<PushRouter/>
			<WhitePagesRouter/>
			<AdminApp/>
			<TeamsRouter />
			<AvatarGalleryRouter/>
			<AppSpotlight/>
		</Shell>
	);
}

function AppLoader() {
	return (
		<MantineProvider theme={appTheme} forceColorScheme={'dark'} withCssVariables>
			<UserContext>
				<TeamsContextProvider>
					<ModalsProvider>
						<LocaleProvider>
							<App/>
						</LocaleProvider>
					</ModalsProvider>
				</TeamsContextProvider>
			</UserContext>
			<Notifications position={'top-center'}/>
		</MantineProvider>

	)
}

export default AppLoader
