import {useEffect, useState} from "react";
import {Link, useParams} from "wouter";
import {usePromise} from "@hooks/usePromise.ts";
import {YoContextEditor} from "@components/YoContextEditor";
import {InputField} from "@components/YoContextEditor/components/inputField.tsx";
import {Button} from "@mantine/core";
import {notifications} from "@mantine/notifications";

import {FbPixelsApiClient} from "./api.ts";
import {Header} from "@components/Header";
import {ConfirmDeleteModal} from "@modals/confirmModal.tsx";
import {useNavigate} from "@hooks/useNavigate.tsx";
import {Translation, useLocale} from "../../locale";
import {PixelStats} from "./pixelStats.tsx";
import {displayErrorNotification} from "@serviceComponents/displayErrorNotification.tsx";
import {FbEventField} from "./fbEventField.tsx";
import TeamSelectField from "@components/YoContextEditor/teamSelect.tsx";

const api = new FbPixelsApiClient();

export function ViewFbPixels() {
	const t = useLocale();
	const navigate = useNavigate();
	const params = useParams<{id: string}>();
	const promise = usePromise(() => api.fetchItem(params.id), [params.id]);
	const [data, setData] = useState({});
	const [changes, setChanges] = useState({});
	useEffect(() => setData(promise.data?.item ?? {}), [promise.isLoading]);

	function trySave() {
		api.saveItem(params.id, changes).then(() => {
			notifications.show({message: t('fbPixels.edit.saveSuccess')});
			setChanges({});
		}).catch(displayErrorNotification)
	}

	async function tryRemove() {
		if(!await ConfirmDeleteModal()) return false;
		return api.deleteItem(params.id).then(() => navigate('/'))
	}

	return (
		<YoContextEditor item={data} setItem={setData} changes={changes} setChanges={setChanges}>
			<Header
				title={<Translation id={'fbPixels.edit.headerTitle'}/>}
				items={(
					<>
						<Link href={`/logs/${params.id}`}>
							<Button size={'compact-sm'} color={'green'}>
								<Translation id={'fbPixels.edit.logsButton'}/>
							</Button>
						</Link>
						<Button onClick={trySave} size={'compact-sm'}>
							<Translation id={'fbPixels.edit.saveButton'}/>
						</Button>
						<Button size={'compact-sm'} color={'red'} onClick={tryRemove}>
							<Translation id={'fbPixels.edit.deleteButton'}/>
						</Button>
					</>
				)}
			/>
			<TeamSelectField />
			<InputField field={'name'} label={<Translation id={'fbPixels.edit.pixelNameLabel'}/>}/>
			<InputField field={'pixel'} label={<Translation id={'fbPixels.edit.pixelIdLabel'}/>}/>
			<InputField field={'token'} label={<Translation id={'fbPixels.edit.pixelTokenLabel'}/>}/>
			<h4>
				<Translation id={'fbPixels.edit.eventsMapping.title'}/>
			</h4>
			<FbEventField field={'installEventName'} label={t('fbPixels.edit.eventsMapping.installEventNameLabel')}/>
			<FbEventField field={'registrationEventName'} label={t('fbPixels.edit.eventsMapping.registrationEventNameLabel')}/>
			<FbEventField field={'depositEventName'} label={t('fbPixels.edit.eventsMapping.depositEventNameLabel')}/>
			<h4>
				<Translation id={'fbPixels.stat.title'}/>
			</h4>
			<PixelStats id={params.id}/>
		</YoContextEditor>
	);
}
