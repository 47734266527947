import {
    Alert, AppShell,
    Button,
    Card,
    Checkbox,
    createTheme, DefaultMantineColor, MantineColorsTuple,
    Radio,
    RadioCard, RadioIndicator,
    Slider,
    Tabs
} from "@mantine/core";
import buttonClasses from "./assets/theme/Button.module.css";
import cardClasses from "./assets/theme/Card.module.css";
import alertClasses from "./assets/theme/Alert.module.css";
import tabClasses from "./assets/theme/Tabs.module.css";
import sliderClasses from "./assets/theme/Slider.module.css";
import checkboxClasses from "./assets/theme/Checkbox.module.css";
import radioClasses from "./assets/theme/Radio.module.css";
import appShellClasses from "./assets/theme/AppShell.module.css";
import radioCardClasses from "./assets/theme/RadioCard.module.css";
import radioIndicatorClasses from "./assets/theme/RadioIndicator.module.css";

type ExtendedCustomColors =
    | 'brand'
    | DefaultMantineColor;

declare module '@mantine/core' {
    export interface MantineThemeColorsOverride {
        colors: Record<ExtendedCustomColors, MantineColorsTuple>;
    }
}

export const appTheme = createTheme({
    fontFamily: "Rubik, sans-serif",
    colors: {
        brand: [
            '#ddfeff',
            '#c8f8ff',
            '#97eeff',
            '#61e3ff',
            '#36daff',
            '#18d5ff',
            '#00d3ff',
            '#00bae5',
            '#00a6cd',
            '#0090b5'
        ]
    },
    white: '#DDE2F6',
    defaultGradient: {
        from: '#1DD6FF00',
        to: '#1DD6FF33',
        deg: 90
    },
    primaryColor: 'brand',
    cursorType: 'pointer',
    defaultRadius: 10,
    components: {
        Modal: {
            defaultProps: {
                // в дизайне все модальки отцентрованы, хз насколько это важно, выглядит странно
                centered: true,
                styles: {
                    overlay: {
                        background: 'linear-gradient(180deg, rgba(29, 214, 255, 0) 0%, rgba(29, 214, 255, 0.2) 100%)',
                        backdropFilter: 'blur(10px)',
                    }
                }
            },
        },
        Badge: {
            defaultProps: {
                radius: 'sm'
            }
        },
        Button: Button.extend({
            classNames: buttonClasses,
        }),
        Table: {
            defaultProps: {
                withColumnBorders: true,
                styles: {
                    th: {
                        borderLeft: 'none',
                        borderRight: 'none',
                        textTransform: 'uppercase'
                    }
                }
            }
        },
        Card: Card.extend({
            classNames: cardClasses,
        }),
        Fieldset: {
            defaultProps: {
                bg: '#0B0E21'
            }
        },
        Alert: Alert.extend({
            classNames: alertClasses
        }),
        TagsInput: {
            defaultProps: {
                styles: {
                    input: {
                        backgroundColor: '#0B0E21'
                    },
                    pill: {
                        backgroundColor: '#12162D'
                    }
                }
            }
        },
        Tabs: Tabs.extend({
            classNames: tabClasses
        }),
        Slider: Slider.extend({
            classNames: sliderClasses
        }),
        Text: {
            defaultProps: {
                color: '#DDE2F6'
            }
        },
        Checkbox: Checkbox.extend({
            classNames: checkboxClasses,
            defaultProps: {
                radius: 'sm'
            }
        }),
        Radio: Radio.extend({
            classNames: radioClasses
        }),
        RadioCard: RadioCard.extend({
            classNames: radioCardClasses
        }),
        RadioIndicator: RadioIndicator.extend({
            classNames: radioIndicatorClasses
        }),
        AppShell: AppShell.extend({
            classNames: appShellClasses
        })
    }
});