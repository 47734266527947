import {createContext, ReactNode, useContext} from 'react';
import {useLocalStorage} from '@mantine/hooks';
import {locale as ru} from './locales/ru.ts';
import {locale as uk} from './locales/uk.ts';
import {locale as en} from './locales/en.ts';
import {LocaleKeys} from "./localeKeys.tsx";

interface LocaleContextType { strings: Record<LocaleKeys, string>, selectedLocale: keyof typeof localeRegistry }

const Ctx = createContext<LocaleContextType>({ strings: ru, selectedLocale: 'ru' });

const localeRegistry: Record<'ru' | 'uk' | 'en', typeof ru> = {
	ru,
	uk,
	en
};

interface LocaleProviderProps {
	children: ReactNode;
}

// Компонент провайдера локали
export function LocaleProvider({ children }: LocaleProviderProps) {
	const [selectedLocale] = useLocalStorage<keyof typeof localeRegistry >({
		key: 'engine.locale',
		defaultValue: 'uk',
	});

	const locale = localeRegistry[selectedLocale] || localeRegistry.ru;

	return (
		<Ctx.Provider value={{ strings: locale, selectedLocale }}>
			{children}
		</Ctx.Provider>
	);
}

export function useLocaleContext() {
	return useContext(Ctx);
}

export function useLocale() {
	const ctx = useLocaleContext();

	return (key: LocaleKeys) => ctx.strings[key] ?? key;
}
// TODO Объединить с useLocale
export function useScopedLocale<TPrefix extends string>(prefix: TPrefix) {
	const t = useLocale();

	type RemovePrefix<T> = T extends `${TPrefix}${infer U}` ? U : never;

	return <T extends RemovePrefix<LocaleKeys>>(key: T) => t(`${prefix}${key}` as LocaleKeys);
}


export function Translation({ id }: { id: LocaleKeys }) {
	const loc = useLocale();

	return <>{loc(id)}</>;
}

let cachedLocale = '';

export function getLocaleString(key: LocaleKeys) {
	let currentLocale = cachedLocale as keyof typeof localeRegistry;
	if(!currentLocale) {
		try {
			cachedLocale = JSON.parse(localStorage.getItem('engine.locale') ?? '') as keyof typeof localeRegistry;
		} catch (e) {
			cachedLocale = localStorage.getItem('engine.locale') as keyof typeof localeRegistry;
		}
		currentLocale = cachedLocale as keyof typeof localeRegistry;
	}
	return localeRegistry[currentLocale ?? 'ru'][key] ?? key;
}
