import {useEffect, useRef, useState} from "react";
import {useYoProvider} from "@components/YoContextEditor";
import {IGPlayInfo} from "@shared/interfaces/IWebAppWorkerRecord.ts";
import {Box, LoadingOverlay} from "@mantine/core";
import {useUser} from "@engine/UserContext.tsx";

function makeData(data: any, security: any): IGPlayInfo {
	const {content, reviews} = data;
	const {screens, ratingValues = {}, whatsNew = {},  ...contentData} = content;
	return {
		...contentData,
		reviews,
		screens: screens.map((el: any) => ({url: el.image})),
		ratingCharts: {
			5: ratingValues.five ?? 0,
			4: ratingValues.four ?? 0,
			3: ratingValues.three ?? 0,
			2: ratingValues.two ?? 0,
			1: ratingValues.one ?? 0,
		},
		appSecurity: {
			noCollect: security.noDataCollected,
			noShare: security.noDataShare,
		},
		whatsNewContent: whatsNew.enabled ? whatsNew.content : '',
	};
}

export function PreviewFrame(props: { locale: string }) {
	const {user, settings = {}} = useUser();
	const ref = useRef<HTMLIFrameElement>(null);
	const bridgeReceivedFn = useRef<((e: MessageEvent) => void) | null>(null);
	const [loading, setLoading] = useState(true);
	const securityCtx = useYoProvider(`security`, {defaultValue: {noDataCollected: false, noDataShare: false}});
	const ctx = useYoProvider(`localeAppData.${props.locale}`);

	useEffect(() => {
		if (ref.current) {
			ref.current.contentWindow?.postMessage({type: 'update', data: makeData(ctx.value, securityCtx.value)}, '*');
		}
	}, [JSON.stringify(ctx.value)]);

	useEffect(() => {
		return () => {
			if(bridgeReceivedFn.current) {
				console.log(`remove global 'message' event listener`);
				window.removeEventListener('message', bridgeReceivedFn.current);
			}
		}
	}, []);

	function initFrame(frameElement: HTMLIFrameElement) {
		setLoading(false);

		function onMessage(e: any) {
			if(e.origin !== settings.previewUrl) return;
			console.log(`A message was received from a preview frame`, e.data);
			if(e.data.type === 'onReady') {
				frameElement.contentWindow?.postMessage({type: 'init', data: makeData(ctx.value, securityCtx.value)}, '*');
			}
		}

		window.addEventListener('message', onMessage);
		bridgeReceivedFn.current = onMessage;
		frameElement.contentWindow?.postMessage({type: 'init', data: makeData(ctx.value, securityCtx.value)}, '*');
	}

	return (
		<Box pos="relative" h={'80vh'}>
			<LoadingOverlay visible={loading}/>
			{ctx.value && (
				<iframe
					onLoadStart={() => setLoading(true)}
					onLoad={e => initFrame(e.target as HTMLIFrameElement)}
					ref={ref}
					src={`${settings.previewUrl}/?lang=${props.locale}&uid=${user._id}`}
					style={{width: '100%', height: '100%'}}
				/>
			)}
		</Box>
	);
}
