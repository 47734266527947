import {useEffect, useState} from "react";
import {Link, useParams} from "wouter";
import {usePromise} from "@hooks/usePromise.ts";
import {useYoProvider, YoContextEditor} from "@components/YoContextEditor";
import {InputField} from "@components/YoContextEditor/components/inputField.tsx";
import {Badge, Button, Flex, Menu, SimpleGrid, Spoiler, Textarea, TextInput} from "@mantine/core";

import {LeadsApiClient} from "./api.ts";
import {Header} from "@components/Header";
import {ConfirmDeleteModal} from "@modals/confirmModal.tsx";
import {useNavigate} from "@hooks/useNavigate.tsx";
import {RenderFuncField} from "@components/YoContextEditor/components/renderFuncField.tsx";
import {YoContextArrayMap} from "@components/YoContextEditor/YoContextArrayMap.tsx";
import {Translation, useLocale} from "../../locale";
import {yoFormModal} from "@modals/yoFormModal.tsx";
import {TextareaField} from "@components/YoContextEditor/components/textareaField.tsx";
import {notifications} from "@mantine/notifications";
import {displayErrorNotification} from "@serviceComponents/displayErrorNotification.tsx";
import {CheckboxField} from "@components/YoContextEditor/components/checkboxField.tsx";
import {CollapseField, notEq} from "@components/YoContextEditor/components/CollapseField.tsx";
import {ImageUploaderField} from "@components/YoContextEditor/components/imageUploaderField.tsx";

import {ISendPushProps} from "@serverComponents/Leads/interfaces/ISendPushProps.ts";
import {SelectPushMessage} from "../Push/PushMessages/SelectPushMessages.tsx";

const api = new LeadsApiClient();

function PushStatusDisplay() {
	const ctx = useYoProvider('pushSubscription');

	if (!ctx.value || !ctx.value.isSubscribed) {
		return (
			<>
				<Badge fullWidth color={'yellow'}>
					<Translation id={'leads.view.push.notSubscribed'}/>
				</Badge>
			</>
		)
	}

	return (
		<Badge fullWidth color={'indigo'}>
			<Translation id={'leads.view.push.subscribed'}/> {new Date(ctx.value.updatedAt).toLocaleString()}
		</Badge>
	)
}

function LeadStatusFlagDisplay(props: { field: string, label: string, timeField?: string }) {
	const boolCtx = useYoProvider<boolean>(props.field);
	const dateCtx = useYoProvider<string>(props.timeField || `${props.field}At`);
	const t = useLocale();

	if (boolCtx.value) {
		return (
			<Badge fullWidth color={'indigo'}>
				{props.label}: {t('leads.view.time')}: {dateCtx.value ? new Date(dateCtx.value).toLocaleString() : t('leads.view.unknown')}
			</Badge>
		);
	}

	return (
		<Badge fullWidth color={'yellow'}>
			{props.label}
		</Badge>
	);
}

export function ViewLeads() {
	const t = useLocale();
	const navigate = useNavigate();
	const params = useParams<{ id: string }>();
	const promise = usePromise(() => api.fetchItem(params.id), [params.id]);
	const [data, setData] = useState<any>({});
	const [changes, setChanges] = useState({});
	useEffect(() => setData(promise.data?.item ?? {}), [promise.isLoading]);


	async function tryRemove() {
		if (!await ConfirmDeleteModal()) return false;
		return api.deleteItem(params.id).then(() => navigate('/'))
	}

	async function trySendPush() {
		const data = await yoFormModal<ISendPushProps>({
			title: t('leads.view.sendPush.title'),
			body: (
				<>
					<InputField field={'title'} label={t('leads.view.sendPush.form.title')}/>
					<TextareaField field={'message'} label={t('leads.view.sendPush.form.message')}/>
					<br/>
					<CheckboxField
						field={'openApp'}
						label={t('leads.view.sendPush.form.openApp')}
						description={t('leads.view.sendPush.form.openAppDescription')}
					/>
					<br/>
					<CollapseField field={'openApp'} eqFn={notEq}>
						<InputField field={'targetLink'} label={t('leads.view.sendPush.form.targetLink')}/>
					</CollapseField>
					<h4>
						<Translation id={'leads.view.sendPush.form.image'}/>
					</h4>
					<ImageUploaderField field={'image'}/>
				</>
			)
		});
		return api
			.sendPush(params.id, data).then(() => notifications.show({message: t('leads.view.sendPush.success')}))
			.catch(displayErrorNotification)
	}

	async function trySendTemplatePush() {
		const data = await yoFormModal({
			title: t('leads.view.sendPush.title'),
			required: ['messageId'],
			body: (
				<>
					<RenderFuncField<string> field={'messageId'}>
						{ctx => <SelectPushMessage label={'push message id'} value={ctx.value} onChange={ctx.onChange}/>}
					</RenderFuncField>
				</>
			)
		});
		return api
			.sendPushByMessageId(params.id, data.messageId).then(() => notifications.show({message: t('leads.view.sendPush.success')}))
			.catch(displayErrorNotification)
	}

	return (
		<YoContextEditor item={data} setItem={setData} changes={changes} setChanges={setChanges}>
			<Header
				title={t('leads.view.title')}
				items={(
					<>
						<Button size={'compact-sm'} color={'red'} onClick={tryRemove}>{t('leads.view.delete')}</Button>
					</>
				)}
			/>
			<div>
				<RenderFuncField field={'appId'}>
					{ctx => (
						<Link href={`~/webApps/v/${ctx.value}`}>
							<Button>
								{t('leads.view.goToApp')}
							</Button>
						</Link>
					)}
				</RenderFuncField>
				<RenderFuncField field={'clientId'}>
					{ctx => (
						<>
							<Link href={`~/leads?clientId=${ctx.value}`}>
								<Button>{t('leads.view.allLeadsForClient')}</Button>
							</Link>
							<Link href={`~/leads/logs?clientId=${ctx.value}`}>
								<Button>{t('leads.view.clientLogs')}</Button>
							</Link>
						</>
					)}
				</RenderFuncField>
				<RenderFuncField field={'runId'}>
					{ctx => (
						<Link href={`~/leads/logs?runId=${ctx.value}`}>
							<Button>{t('leads.view.sessionLogs')}</Button>
						</Link>
					)}
				</RenderFuncField>
				<RenderFuncField field={'pushSubscription.isSubscribed'}>
					{ctx => (
						<Menu shadow="md" width={200}>
							<Menu.Target>
								<Button disabled={!ctx.value}>
									<Translation id={'leads.view.push.sendPush'}/>
								</Button>
							</Menu.Target>

							<Menu.Dropdown>
								<Menu.Item onClick={trySendPush}>
									<Translation id={'leads.view.push.sendPush'}/>
								</Menu.Item>
								<Menu.Item onClick={trySendTemplatePush}>
									<Translation id={'leads.view.push.sendPush'}/> (Заготовка)
								</Menu.Item>
							</Menu.Dropdown>
						</Menu>
					)}
				</RenderFuncField>
			</div>
			<h4>{t('leads.view.clientStatus')}</h4>
			<Flex justify={'space-between'} gap={'sm'} align={'center'}>
				<LeadStatusFlagDisplay field={'appInstalled'} label={t('leads.view.appInstalled')}/>
				<LeadStatusFlagDisplay field={'appLaunched'} timeField={'appLaunchedFirstAt'} label={t('leads.view.appLaunched')}/>
				<PushStatusDisplay/>
			</Flex>
			<hr/>
			<Flex justify={'space-between'} gap={'sm'} align={'center'}>
				<LeadStatusFlagDisplay field={'userRegistered'} label={t('leads.view.userRegistered')}/>
				<LeadStatusFlagDisplay field={'userDeposited'} label={t('leads.view.userDeposited')}/>
			</Flex>
			<hr/>
			<InputField field={'clientIp'} label={t('leads.view.clientIp')} readOnly/>
			<h4>{t('leads.view.otherClientIps')}</h4>
			<YoContextArrayMap field={'clientIps'}>
				<InputField field={'ip'} readOnly/>
			</YoContextArrayMap>
			<h4>{t('leads.view.browserInfo')}</h4>
			<InputField field={'userAgent'} label={t('leads.view.userAgent')} readOnly/>
			<InputField field={'browserName'} label={t('leads.view.browserName')} readOnly/>
			<InputField field={'browserVersion'} label={t('leads.view.browserVersion')} readOnly/>
			<InputField field={'osName'} label={t('leads.view.osName')} readOnly/>
			<InputField field={'osVersion'} label={t('leads.view.osVersion')} readOnly/>
			<InputField field={'deviceType'} label={t('leads.view.deviceType')} readOnly/>
			<InputField field={'tz'} label={t('leads.view.timezone')} readOnly/>

			<h4>{t('leads.view.analytics')}</h4>
			<h5>{t('leads.view.primary')}</h5>
			<InputField field={'referer'} label={t('leads.view.referer')} readOnly/>
			<SimpleGrid cols={2}>
				<div>
					<h5>{t('leads.view.facebook')}</h5>
					<InputField field={'fbc'} label={t('leads.view.fbc')} readOnly/>
					<InputField field={'fbp'} label={t('leads.view.fbp')} readOnly/>
				</div>
				<div>
					<h5>{t('leads.view.google')}</h5>
					<InputField field={'gclid'} label={t('leads.view.gclid')} readOnly/>
				</div>
			</SimpleGrid>
			<SimpleGrid cols={2}>
				<div>
					<h4>{t('leads.view.rawRequestHeaders')}</h4>
					<Spoiler maxHeight={120} showLabel={t('leads.view.showAll')} hideLabel={t('leads.view.hide')}>
						<YoContextArrayMap field={'rawHeaders'}>
							<RenderFuncField<{ key: string, value: string }> >
								{ctx => <TextInput label={ctx.value.key} value={ctx.value.value} readOnly/>}
							</RenderFuncField>
						</YoContextArrayMap>
					</Spoiler>
				</div>
				<div>
					<h4>{t('leads.view.rawQueryParams')}</h4>
					<Spoiler maxHeight={120} showLabel={t('leads.view.showAll')} hideLabel={t('leads.view.hide')}>
						{Object.entries(data.queryParams ?? {}).map(([key, value]) => (
							<Textarea autosize key={key} label={key} value={JSON.stringify(value, null, 2)} readOnly/>
						))}
					</Spoiler>
				</div>
			</SimpleGrid>
		</YoContextEditor>
	);
}
