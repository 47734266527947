import {Card, Title, Text, Stack} from "@mantine/core";
import {SelectField} from "@components/YoContextEditor/components/selectField.tsx";
import {CollapseField, eqString} from "@components/YoContextEditor/components/CollapseField.tsx";
import {WebAppStat} from "./webAppStat.tsx";
import {useParams} from "wouter";
import {useScopedLocale} from "../../../locale";
import {PushStats} from "../../Push/PushLogs/pushStats.tsx";

export function PublishTab() {
	const tt = useScopedLocale('webApps.view.PublishTab.');
	const params = useParams<{id: string}>();
	return (
		<Card withBorder>
			<Title order={4}>{tt('title')}</Title>
			<Text size="sm" c="dimmed" mb="md">{tt('description')}</Text>
			<Stack>
				<Card variant="section">
					<SelectField
						field={'deployMode'}
						label={'Куда публикуем'}
						data={[{
							label: 'PWA Cloud',
							value: 'pwaCloud'
						}, {
							label: 'Global Edge Architecture',
							value: 'cloudflare'
						}]}
					/>
				</Card>
				<Card variant="section">
					<SelectField field={'state'} label={'Статус публикации PWA'} data={[{value: 'DRAFT', label: 'Черновик'}, {value: 'PUBLISHED', label: 'Опубликован'}]}/>
				</Card>
				<CollapseField field={'state'} eqFn={eqString('PUBLISHED')}>
					<WebAppStat id={params.id}/>
					<PushStats selector={{appId: params.id}}/>
				</CollapseField>
			</Stack>
		</Card>
	);
}
