import {ActionIcon, Button, Group, Loader, Modal, Stack, Table, Text, TextInput, Title} from "@mantine/core";
import {IconPlus, IconTrash} from "@tabler/icons-react";
import {yoFormModal} from "@modals/yoFormModal.tsx";
import {InputField} from "@components/YoContextEditor/components/inputField.tsx";
import {TeamsApiClient, TTeamUser} from "../api.ts";
import {useEffect, useState} from "react";
import {displayErrorNotification} from "@serviceComponents/displayErrorNotification.tsx";
import {TeamUserRoleKeys} from "@shared/_data/teamUserRoles.ts";
import {openConfirmModal} from "@mantine/modals";
import TeamUserRoleSelect from "../Components/TeamUserRoleSelect.tsx";

const api = new TeamsApiClient();

function CreateNewUser ({ teamId }: { teamId: string }) {
    const [email, setEmail] = useState("");
    const [role, setRole] = useState<TeamUserRoleKeys | null>(null);
    const [createdUser, setCreatedUser] = useState<{ username: string, password: string } | null>(null)
    const [pending, setPending] = useState(false);
    const [opened, setOpened] = useState(false);

    async function tryCreateNewUser () {
        try {
            setPending(true);
            const created = await api.createNewUser(teamId, email, role || 'admin');
            setCreatedUser(created);
            setPending(false);
        } catch (e) {
            setPending(false);
        }
    }

    return (
        <>
            <Modal title="create a new user" opened={opened} onClose={() => !pending && setOpened(false)}>
                {
                    createdUser ? (
                        <Stack>
                            <Title>
                                Created user!
                            </Title>
                            <Group>
                                <Text>
                                    Email:
                                </Text>
                                <Text>
                                    {createdUser.username}
                                </Text>
                            </Group>
                            <Group>
                                <Text>
                                    password:
                                </Text>
                                <Text>
                                    {createdUser.password}
                                </Text>
                            </Group>
                        </Stack>
                    ) : (
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            void tryCreateNewUser();
                        }}>
                            <Stack>
                                <TextInput
                                    required
                                    type="email"
                                    label="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <TeamUserRoleSelect
                                    label="role"
                                    value={role as TeamUserRoleKeys}
                                    onChange={v => {
                                        if (v !== null) {
                                            setRole(v);
                                        }
                                    }}
                                />
                                <Button type="submit">
                                    Create
                                </Button>
                            </Stack>
                        </form>
                    )
                }
            </Modal>
            <Button
                leftSection={<IconPlus />}
                onClick={() => {
                    setPending(false);
                    setOpened(true);
                    setCreatedUser(null);
                    setEmail("");
                }}
                size={'compact-sm'} variant="primary"
            >
                Create New Team User
            </Button>
        </>
    )
}



function MembersList({ teamId }: {teamId: string }) {
    // const t = useScopedLocale('teamsPage.view.membersList.');
    const [usersList, setUsersList] = useState<TTeamUser[] | null>(null);

    async function loadUsersList () {
        try {
            api.getTeamsUsers(teamId)
                .then((data) => {
                    if (!data.status) throw new Error(data.message);
                    setUsersList(data.items);
                })
        } catch (e) {
            void displayErrorNotification(e);
            setUsersList([]);
        }
    }

    useEffect(() => {
        void loadUsersList();
    }, [teamId]);

    async function updateUserRole (userId: string, role: string) {
        api.updateUserRole(teamId, userId, role)
            .then(() => loadUsersList())
    }

    async function deleteTeamUser (teamUserId: string) {
        openConfirmModal({
            title: "Are you sure?",
            labels: {
                cancel: 'Cancel',
                confirm: 'Delete user',
            },
            onConfirm: () => {
                api.deleteTeamUser(teamId, teamUserId)
                    .then(() => loadUsersList())
            }
        })
    }

    async function tryInvite () {
        const data = await yoFormModal<any>({
            title: 'Invite user',
            required: ['email'],
            defaultValue: {},
            body: (
                <>
                    <InputField field={'email'} label="email" />
                </>
            )
        })
        return api.inviteUser(teamId, data.email, 'admin')
            .then(() => {
                setTimeout(() => {
                    window.location.reload()
                }, 1000)
            })
    }

    return (
        <Table withTableBorder>
            <Table.Thead>
                <Table.Tr>
                    <Table.Th>email</Table.Th>
                    <Table.Th>role</Table.Th>
                    <Table.Th>
                        <Group gap="xs">
                            <Button leftSection={<IconPlus />} onClick={tryInvite} size={'compact-sm'} variant="primary">
                                Invite
                            </Button>
                            <CreateNewUser teamId={teamId} />
                        </Group>
                    </Table.Th>
                </Table.Tr>
            </Table.Thead>
                {
                    !usersList ? (
                        <Loader />
                    ) : (
                        <Table.Tbody>
                            {
                                usersList.map(item => (
                                    <Table.Tr key={item._id}>
                                        <Table.Td>
                                            {item.username}
                                        </Table.Td>
                                        <Table.Td>
                                            <TeamUserRoleSelect
                                                value={item.role as TeamUserRoleKeys}
                                                onChange={v => {
                                                    if (v !== null) {
                                                        void updateUserRole(item._id, v);
                                                    }
                                                }}
                                            />
                                        </Table.Td>
                                        <Table.Td>
                                            <ActionIcon
                                                color="red"
                                                variant="outline"
                                                onClick={() => deleteTeamUser(item._id)}
                                            >
                                                <IconTrash />
                                            </ActionIcon>
                                        </Table.Td>
                                    </Table.Tr>
                                ))
                            }
                        </Table.Tbody>
                    )
                }
        </Table>
    );
}

export default MembersList;