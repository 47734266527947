import {CheckboxField} from "@components/YoContextEditor/components/checkboxField.tsx";
import {YoContextEditor} from "@components/YoContextEditor";
import {RenderFuncField} from "@components/YoContextEditor/components/renderFuncField.tsx";
import {Card, Flex, Radio, Slider, Stack, Text, Title} from "@mantine/core";
import {CollapseField, eqString} from "@components/YoContextEditor/components/CollapseField.tsx";
import {NumberField} from "@components/YoContextEditor/components/numberField.tsx";
import {useScopedLocale} from "../../../../locale";

export function ContentSettingsTab() {
	const tt = useScopedLocale('webApps.views.ContentSettingsTab.');
	const ct = useScopedLocale('webApps.views.ContentTab.');

	return (
		<Card withBorder>
			<Stack gap="xs">
				<Title order={4} mb="lg">
					{ct('tabsList.additional')}
				</Title>
				<Title order={5}>{tt('security.title')}</Title>
				<Card variant="section" mb="md">
					<CheckboxField
						mb="sm"
						field={'security.noDataCollected'}
						label={tt('security.noDataCollected')}
					/>
					<CheckboxField
						field={'security.noDataShare'}
						label={tt('security.noDataShare')}
					/>
				</Card>
				<Title order={4}>{tt('installButtonBehavior.title')}</Title>
				<YoContextEditor name={'installButtonBehavior'}>
					<RenderFuncField<'waitInstall' | 'scrollToBottom' | 'none'> field={'behavior'}>
						{ctx => (
							<Radio.Card
								checked={ctx.value === 'waitInstall'}
								onClick={() => ctx.onChange('waitInstall')}
							>
								<Flex gap={'sm'} align={'center'} mb="sm">
									<Radio.Indicator style={{margin: '10px'}}/>
									<div>
										<Text size="lg" fw={600} style={{marginTop: '10px'}}>{tt('installButtonBehavior.waitInstall.title')}</Text>
										<Text c="dimmed" size="xs">{tt('installButtonBehavior.waitInstall.description')}</Text>
									</div>
								</Flex>
								<CollapseField field={'behavior'} eqFn={eqString('waitInstall')}>
									<CheckboxField
										mt={0}
										m="md"
										field={'waitInstall.scrollToBottom'}
										label={tt('installButtonBehavior.waitInstall.scrollToBottom.label')}
										description={tt('installButtonBehavior.waitInstall.scrollToBottom.description')}
									/>
								</CollapseField>
							</Radio.Card>
						)}
					</RenderFuncField>
					<RenderFuncField<'waitInstall' | 'scrollToBottom' | 'none'> field={'behavior'}>
						{ctx => (
							<Radio.Card
								checked={ctx.value === 'scrollToBottom'}
								onClick={() => ctx.onChange('scrollToBottom')}
							>
								<Flex gap={'sm'} align={'center'} mb="sm">
									<Radio.Indicator style={{margin: '10px'}}/>
									<div>
										<Text size="lg" fw={600} style={{marginTop: '10px'}}>{tt('behavior.scrollToBottom.title')}</Text>
										<Text c="dimmed" size="xs">{tt('behavior.scrollToBottom.description')}</Text>
									</div>
								</Flex>
								<CollapseField field={'behavior'} eqFn={eqString('scrollToBottom')}>
									<NumberField
										mt={0}
										m="md"
										field={'scrollToBottom.clickCount'}
										label={tt('behavior.scrollToBottom.clickCount.label')}
									/>
								</CollapseField>
							</Radio.Card>
						)}
					</RenderFuncField>
					<RenderFuncField<'waitInstall' | 'scrollToBottom' | 'none'> field={'behavior'}>
						{ctx => (
							<Radio.Card
								mb="md"
								checked={ctx.value === 'none'}
								onClick={() => ctx.onChange('none')}
							>
								<Flex gap={'sm'} align={'center'} mb="sm">
									<Radio.Indicator style={{margin: '10px'}}/>
									<div>
										<Text size="lg" fw={600} style={{marginTop: '10px'}}>{tt('behavior.none.title')}</Text>
										<Text c="dimmed" size="xs">{tt('behavior.none.description')}</Text>
									</div>
								</Flex>
							</Radio.Card>
						)}
					</RenderFuncField>
				</YoContextEditor>
				<Title order={4}>{tt('tweaks.title')}</Title>
				<Card variant="section" mb="md">
					<CheckboxField
						field={'tweaks.hideHeader'}
						label={tt('tweaks.label')}
						description={tt('tweaks.description')}
					/>
					<CheckboxField
						field={'tweaks.reloadPageIfInstallNotAllowed'}
						label={tt('tweaks.reloadPageIfInstallNotAllowed')}
					/>
				</Card>
				<Title order={4}>{tt('tweaks.installButtonAnimSpeed')}</Title>
				<Card variant="section" mb="md" style={{ overflow: "unset" }}>
					<RenderFuncField<number> field={'tweaks.installButtonAnimSpeed'}>
						{ctx => <Slider min={1} max={100} value={ctx.value ?? 50} onChange={el => ctx.onChange(el)}/>}
					</RenderFuncField>
				</Card>
			</Stack>
		</Card>
	);
}
